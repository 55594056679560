"use strict";

import * as THREE from 'three';
import CardboardVRDisplay from 'cardboard-vr-display';
import KalmanFilter from 'kalmanjs';


import boxPng from './box.png'

export function View3d(params) {
    
  const song = document.getElementById('song');
  song.volume = 0;

  /*
  const requestSoundOn = document.getElementById('requestSoundOn');
  requestSoundOn.classList.remove('hidden');
  requestSoundOn.onclick = () => {
     song.play();
     requestSoundOn.classList.add('hidden');
  };
  */

  var scanScarfNotice = document.getElementById('scanScarfNotice');
  scanScarfNotice.classList.remove('hidden');
  scanScarfNotice.onclick = () => {
    song.play();
    scanScarfNotice.classList.add('hidden');
  };


  // INIT DEVICE MOTION CONTROLS / START ======================================================================================

  const requestDeviceMotion = document.getElementById('requestDeviceMotion');

  this.requestDeviceMotionHandler = function() {
    window.DeviceMotionEvent.requestPermission()
      .then(response => {
        requestDeviceMotion.classList.add('hidden');
        if (response === 'granted') {
          window.addEventListener('devicemotion',
            () => { console.log('DeviceMotion permissions granted.') },
            (e) => { throw e }
        )} else {
          console.log('DeviceMotion permissions not granted.')
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  // Check if is IOS 13 when page loads.
  if ( window.DeviceMotionEvent && typeof window.DeviceMotionEvent.requestPermission === 'function' ){

      var testListener = () => { 
        requestDeviceMotion.classList.add('hidden');
        window.removeEventListener('devicemotion', testListener);
      }
      window.addEventListener('devicemotion', testListener);
     
      requestDeviceMotion.classList.remove('hidden');
      requestDeviceMotion.onclick = () => {
        window.DeviceMotionEvent.requestPermission()
          .then(response => {
            requestDeviceMotion.classList.add('hidden');
            if (response === 'granted') {
              window.addEventListener('devicemotion',
                () => { console.log('DeviceMotion permissions granted.') },
                (e) => { throw e }
            )} else {
              console.log('DeviceMotion permissions not granted.')
            }
          })
          .catch(e => {
            console.error(e)
          })
      }; // You NEED to bind the function into a onClick event. An artificial 'onClick' will NOT work.
  }

  // Default options
  const options = {
    // Optionally inject custom Viewer parameters as an option. Each item
    // in the array must be an object with the following properties; here is
    // an example of the built in CardboardV2 viewer:
    //
    // {
    //   id: 'CardboardV2',
    //   label: 'Cardboard I/O 2015',
    //   fov: 60,
    //   interLensDistance: 0.064,
    //   baselineLensDistance: 0.035,
    //   screenLensDistance: 0.039,
    //   distortionCoefficients: [0.34, 0.55],
    //   inverseCoefficients: [-0.33836704, -0.18162185, 0.862655, -1.2462051,
    //     1.0560602, -0.58208317, 0.21609078, -0.05444823, 0.009177956,
    //     -9.904169E-4, 6.183535E-5, -1.6981803E-6]
    // }
    // Added in 1.0.12.
    ADDITIONAL_VIEWERS: [],

    // Select the viewer by ID. If unspecified, defaults to 'CardboardV1'.
    // Added in 1.0.12.
    DEFAULT_VIEWER: '',

    // By default, on mobile, a wakelock is necessary to prevent the device's screen
    // from turning off without user input. Disable if you're keeping the screen awake through
    // other means on mobile. A wakelock is never used on desktop.
    // Added in 1.0.3.
    MOBILE_WAKE_LOCK: true,

    // Whether or not CardboardVRDisplay is in debug mode. Logs extra
    // messages. Added in 1.0.2.
    DEBUG: false,

    // The URL to JSON of DPDB information. By default, uses the data
    // from https://github.com/WebVRRocks/webvr-polyfill-dpdb; if left
    // falsy, then no attempt is made.
    // Added in 1.0.1
    DPDB_URL: 'https://dpdb.webvr.rocks/dpdb.json',

    // Complementary filter coefficient. 0 for accelerometer, 1 for gyro.
    K_FILTER: 0.98,

    // How far into the future to predict during fast motion (in seconds).
    PREDICTION_TIME_S: 0.040,

    // Flag to disabled the UI in VR Mode.
    CARDBOARD_UI_DISABLED: false,

    // Flag to disable the instructions to rotate your device.
    ROTATE_INSTRUCTIONS_DISABLED: false,

    // Enable yaw panning only, disabling roll and pitch. This can be useful
    // for panoramas with nothing interesting above or below.
    YAW_ONLY: false,

    // Scales the recommended buffer size reported by WebVR, which can improve
    // performance.
    // UPDATE(2016-05-03): Setting this to 0.5 by default since 1.0 does not
    // perform well on many mobile devices.
    BUFFER_SCALE: 0.5,

    // Allow VRDisplay.submitFrame to change gl bindings, which is more
    // efficient if the application code will re-bind its resources on the
    // next frame anyway. This has been seen to cause rendering glitches with
    // THREE.js.
    // Dirty bindings include: gl.FRAMEBUFFER_BINDING, gl.CURRENT_PROGRAM,
    // gl.ARRAY_BUFFER_BINDING, gl.ELEMENT_ARRAY_BUFFER_BINDING,
    // and gl.TEXTURE_BINDING_2D for texture unit 0.
    DIRTY_SUBMIT_FRAME_BINDINGS: false,
  };

  const vrDisplay = new CardboardVRDisplay(options);

  function MockVRFrameData () {
    this.leftViewMatrix = new Float32Array(16);
    this.rightViewMatrix = new Float32Array(16);
    this.leftProjectionMatrix = new Float32Array(16);
    this.rightProjectionMatrix = new Float32Array(16);
    this.pose = null;
  };

  const frame = new (window.VRFrameData || MockVRFrameData)();

  // INIT DEVICE MOTION CONTROLS / END ======================================================================================


  // INIT THREE.JS / START ======================================================================================


  // Setup three.js WebGL renderer. Note: Antialiasing is a big performance hit.
  // Only enable it if you actually need to.
  const canvas = document.querySelector('#canvasThree');
  const renderer = new THREE.WebGLRenderer({antialias: false, canvas: canvas, alpha: true});
  renderer.setPixelRatio(Math.floor(window.devicePixelRatio));
  renderer.setSize(window.innerWidth, window.innerHeight);

  const scene = new THREE.Scene();
 
  // FOV [°] = 2 * arctan ( d [mm] / (2 * f [mm]) )
  // FOV: Camera frustum vertical field of view, from bottom to top of view, in degrees.
  // 60 - 67 vs 37 - https://www.mathscinotes.com/2015/10/samsung-s5-field-of-view/
  // 63.54 - https://www.wired.com/2015/05/measuring-field-view-iphone-6-camera/
  // 66 - https://photo.stackexchange.com/questions/106509/what-is-the-angle-of-view-of-the-iphone-xs-rear-camera
  let camera; 
  if(window.innerWidth < window.innerHeight)
    camera = new THREE.PerspectiveCamera(62, window.innerWidth / window.innerHeight, 0.1, 10000);
  else 
    camera = new THREE.PerspectiveCamera(31, window.innerWidth / window.innerHeight, 0.1, 10000);
  scene.add(camera);

  if(false){
    var reticle = new THREE.Mesh(
      new THREE.RingBufferGeometry(0.005, 0.01, 15),
      new THREE.MeshBasicMaterial({ color: 0xffffff })
    );
    reticle.position.z = -0.5;
    camera.add(reticle);
  }









// Tracker distance 695.6521739130435 640 1.0869565217391304 4173 480

  
  var trackerDistance = 360;
  

  var scarfMaterial = new THREE.LineBasicMaterial({
    color: 0xFFFFFF
  });

  var scarfGeometry = new THREE.Geometry();
  for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
    scarfGeometry.vertices.push(
      new THREE.Vector3( tracker_idx * 550, 0, 0 )
    );
  }

  var scarf = new THREE.Line( scarfGeometry, scarfMaterial );
  scarf.frustumCulled = false;
  scarf.position.z = -2000;

  var scarfOrientationGroup = new THREE.Group(); 
  //scarfOrientationGroup.add(scarf);


  var patternVideo = document.getElementById( 'patternVideo' );
  var patternVideoTexture = new THREE.VideoTexture( patternVideo );
  patternVideoTexture.minFilter = THREE.LinearFilter;
  patternVideoTexture.magFilter = THREE.LinearFilter;
  patternVideoTexture.format = THREE.RGBFormat;
  patternVideoTexture.flipY = false;

  var scarfGeometry2 = new THREE.PlaneBufferGeometry( params.tracker_cnt * trackerDistance, 100, params.tracker_cnt -1, 1 );
  
  var scarfMaterial2 = new THREE.MeshBasicMaterial({
    map: patternVideoTexture,
    transparent: true, 
    side: THREE.DoubleSide,
    opacity: 0
  });
/*
  var scarfMaterial2 = new THREE.MeshNormalMaterial({
    side: THREE.DoubleSide,
    opacity: 0.5
  });
  */
  //scarfGeometry.rotateX( - Math.PI / 2 );
  var position = scarfGeometry2.attributes.position;
  position.usage = THREE.DynamicDrawUsage;
  console.log(params.tracker_cnt, position.count);
  /*for ( var i = 0; i < position.count; i ++ ) {
    var y = 35 * Math.sin( i / 2 );
    position.setY( i, y );
  }*/
  var scarf2 = new THREE.Mesh( scarfGeometry2, scarfMaterial2 );
  scarf2.frustumCulled = false;
  scarf2.position.z = -2000;

  scarfOrientationGroup.add(scarf2);


  scene.add( scarfOrientationGroup );


  var kfOptions_Scarf = {
    R: 0.1,
    Q: 0.5,
    A: 1
  }

  scarf2.kfX = new KalmanFilter(kfOptions_Scarf);
  scarf2.kfY = new KalmanFilter(kfOptions_Scarf);
  scarf2.kfZ = new KalmanFilter(kfOptions_Scarf);



  

  // INIT THREE.JS / END ======================================================================================



  // INIT TRACKERS / START ======================================================================================

  var kfOptions_t = {
    R: 2.0,
    Q: 5.0,
    A: 1
  }

  var kfOptions_R = {
    R: 0.1,
    Q: 0.5,
    A: 1
  }

  var kfOptions_Prev = {
    R: 0.1,
    Q: 0.5,
    A: 1
  }

  var trackerGeometry = new THREE.BoxGeometry( 512/3, 384/3, 5 ); //new THREE.SphereGeometry( 120, 32, 32 );
  var trackerMaterial = new THREE.MeshNormalMaterial({
    opacity: 1
  });

  var groups = [];
  var trackerOrientationGroups = [];
  var trackers = [];

  for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
    var tracker = new THREE.Mesh(trackerGeometry, trackerMaterial);

    tracker.kfs = {
        kfX: new KalmanFilter(kfOptions_t),
        kfY: new KalmanFilter(kfOptions_t),
        kfZ: new KalmanFilter(kfOptions_t),

        kf1: new KalmanFilter(kfOptions_R),
        kf2: new KalmanFilter(kfOptions_R),
        kf3: new KalmanFilter(kfOptions_R),
        kf4: new KalmanFilter(kfOptions_R),
        kf5: new KalmanFilter(kfOptions_R),
        kf6: new KalmanFilter(kfOptions_R),
        kf7: new KalmanFilter(kfOptions_R),
        kf8: new KalmanFilter(kfOptions_R),
        kf9: new KalmanFilter(kfOptions_R),

        kfXPrev: new KalmanFilter(kfOptions_Scarf),
        kfYPrev: new KalmanFilter(kfOptions_Scarf),
        kfZPrev: new KalmanFilter(kfOptions_Scarf)
    }

    tracker.countDown = 0;

    tracker.position.z = -100;
    tracker.matrixAutoUpdate = false;

    tracker.positionRelativeToPreviousMarker = new THREE.Vector3(0,0,0);

    trackers.push(tracker);

    var trackerOrientationGroup = new THREE.Group();
    trackerOrientationGroup.add(tracker);
    trackerOrientationGroups.push(trackerOrientationGroup);
    //scene.add(trackerOrientationGroup);
  }

  // INIT TRACKERS / END ======================================================================================




  var referenceTracker_idx = null;

  var orientationCorrection = new THREE.Matrix4();
  var orientationCorrection2 = new THREE.Matrix4();



  // Request animation frame loop function
  vrDisplay.requestAnimationFrame(animate);

  var lastRender = 0;
  function animate(timestamp) {
    var delta = Math.min(timestamp - lastRender, 500);
    lastRender = timestamp;

    vrDisplay.getFrameData(frame);
   
    

  /*
    var position = scarfGeometry.attributes.position;
    for ( var i = 0; i < position.count; i ++ ) {
      var y = 35 * Math.sin( i / 5 + ( timestamp + i ) / 7 );
      position.setY( i, y );
    }
    position.needsUpdate = true;
  */

    position.needsUpdate = true;
    scarfGeometry.verticesNeedUpdate = true;

    updateScene();

    renderer.render(scene, camera);

    vrDisplay.requestAnimationFrame(animate);
  }

  function getTrackerVectorsFromPose(tracker){
    var position = new THREE.Vector3();
    var quaternion = new THREE.Quaternion();
    var scale = new THREE.Vector3();
    tracker.matrix.decompose(position, quaternion, scale);

    var toPrevious = new THREE.Vector3( trackerDistance, 0, 0 ); // 360 bei 8 // 480 bei 6 // 550 auf desktop 415
    toPrevious.applyQuaternion(quaternion);

    var centerUp = new THREE.Vector3( 0, 210, 0 );
    centerUp.applyQuaternion(quaternion);

    return {
      toPrevious: toPrevious, 
      centerUp: centerUp
    };
  }

  function updateScene(){

    var someTrackerActive = false;
    // update the currently active trackers' positions
    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];
      if(tracker.countDown) { // tracker is currently updated and visible
        someTrackerActive = true;
        tracker.visible = true;
        updateTrackerPose(tracker_idx, tracker.latestPose.R, tracker.latestPose.t, tracker.latestPose.updateRate);
      } else { // timeout reached, tracker will become invisible
        tracker.visible = false;
      }
    }

    if(!someTrackerActive) {
      for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
        var tracker = trackers[tracker_idx];
        tracker.observedToPrevious = null;
      }
      scarfMaterial2.opacity = Math.max(0, scarfMaterial2.opacity - 0.1);
      song.volume = Math.max(0, song.volume - 0.1);
    } else {
      scarfMaterial2.opacity = Math.min(1, scarfMaterial2.opacity + 0.1);
      song.volume = Math.min(1, song.volume + 0.1);
    }


    // build a chain of relative tracker positions
    var previousTracker = null;
    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];

      tracker.centerUp = null;
      tracker.toPrevious = null;

      var trackerVectors = getTrackerVectorsFromPose(tracker);
      //tracker.observedToPrevious = null;
      tracker.estimatedToPrevious = trackerVectors.toPrevious;
      tracker.estimatedCenterUp = trackerVectors.centerUp;

      if(tracker.countDown) { // tracker is currently updated and visible
        if(previousTracker !== null) {
          var center = new THREE.Vector3( tracker.matrix.elements[12],
                                          tracker.matrix.elements[13],
                                          tracker.matrix.elements[14]);
          var centerPrevious = new THREE.Vector3( previousTracker.matrix.elements[12],
                                                  previousTracker.matrix.elements[13],
                                                  previousTracker.matrix.elements[14]);
          tracker.observedToPrevious = centerPrevious.sub(center); // if neighbouring tracker is also acitve use real vector
          console.log("true distance", tracker.observedToPrevious.length());
        } else {
          tracker.relativePositionToPreviousTracker = tracker.estimatedToPrevious.clone(); // otherwise estimate vector using pose
        }
        previousTracker = tracker;
      } else { // timeout reached, tracker will become invisible
        previousTracker = null;
      }
    }


    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];
      if(tracker.countDown > 0) {
        if(tracker.observedToPrevious) {
          tracker.toPrevious = new THREE.Vector3(
            tracker.kfs.kfXPrev.filter(tracker.observedToPrevious.x, 2),
            tracker.kfs.kfYPrev.filter(tracker.observedToPrevious.y, 2),
            tracker.kfs.kfZPrev.filter(tracker.observedToPrevious.z, 2)
          );
        } else {
          tracker.toPrevious = new THREE.Vector3(
            tracker.kfs.kfXPrev.filter(tracker.estimatedToPrevious.x, 0),
            tracker.kfs.kfYPrev.filter(tracker.estimatedToPrevious.y, 0),
            tracker.kfs.kfZPrev.filter(tracker.estimatedToPrevious.z, 0)
          );
        }
        tracker.centerUp = tracker.estimatedCenterUp.clone();
      }
    }

    var extrapolate = params.tracker_cnt;
    while(extrapolate--){

      for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
        var tracker = trackers[tracker_idx];

        var previousTracker = tracker_idx -1 >= 0 ? trackers[tracker_idx -1] : null;
        
        var nextTracker = tracker_idx +1 < params.tracker_cnt ? trackers[tracker_idx +1] : null;
        
        if(!tracker.centerUp) {
          var centerUpCount = 0;
          var centerUp = new THREE.Vector3(0,0,0);
          if(previousTracker && previousTracker.centerUp) {
            centerUp.add(previousTracker.centerUp);
            centerUpCount++;
          }
          if(nextTracker && nextTracker.centerUp) {
            centerUp.add(nextTracker.centerUp);
            centerUpCount++;
          }
          if(centerUpCount) {
            tracker.centerUp = centerUp.divideScalar(centerUpCount);
           // console.log('extrapolate centerUp', tracker_idx, tracker.toPrevious);
          } 
        }

        if(!tracker.toPrevious) {
          var toPreviousCount = 0;
          var toPrevious = new THREE.Vector3(0,0,0);
          if(previousTracker && previousTracker.toPrevious) {
            toPrevious.add(previousTracker.toPrevious);
            toPreviousCount++;
          }
          if(nextTracker && nextTracker.toPrevious) {
            toPrevious.add(nextTracker.toPrevious);
            toPreviousCount++;
          }
          if(toPreviousCount && !tracker.toPrevious) {
            tracker.toPrevious = toPrevious.divideScalar(toPreviousCount);
            //console.log('extrapolate toPrevious', tracker_idx, tracker.toPrevious);
          }
        }
      }

    }

    // choose a tracker which is used as reference for positioning the scarf
    var positionDetermined = false;
    
    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];
      if(tracker.countDown) { // tracker is currently updated and visible
        var centerTracker = new THREE.Vector3(
          tracker.matrix.elements[12],
          tracker.matrix.elements[13],
          tracker.matrix.elements[14]);

        if(tracker_idx === referenceTracker_idx) { // this tracker is currenlty used as reference

          scarf.position.copy(centerTracker);
         // scarf2.position.copy(centerTracker);

          scarf2.position.x = scarf2.kfX.filter(centerTracker.x, 1);
          scarf2.position.y = scarf2.kfY.filter(centerTracker.y, 1);
          scarf2.position.z = scarf2.kfZ.filter(centerTracker.z, 1);

          positionDetermined = true;

        } else if (tracker_idx < referenceTracker_idx) { // the reference is more left of the current reference

          var chainIncomplete = false;
          var centerReferenceTracker = centerTracker.clone();
          for(var tracker_idx2 = tracker_idx + 1; tracker_idx2 <= referenceTracker_idx; tracker_idx2++) {
            if(trackers[tracker_idx2].toPrevious) {
              centerReferenceTracker.sub(trackers[tracker_idx2].toPrevious);
            } else {
              chainIncomplete = true;
              break;
            }
          }
          if(!chainIncomplete) {
            scarf.position.copy(centerReferenceTracker);
            //scarf2.position.copy(centerReferenceTracker);
            scarf2.position.x = scarf2.kfX.filter(centerReferenceTracker.x, 1);
            scarf2.position.y = scarf2.kfY.filter(centerReferenceTracker.y, 1);
            scarf2.position.z = scarf2.kfZ.filter(centerReferenceTracker.z, 1);

            positionDetermined = true;
          }

        } else { // tracker_idx > referenceTracker_idx // the reference is more right of the current reference

          var chainIncomplete = false;
          var centerReferenceTracker = centerTracker.clone();
          for(var tracker_idx2 = tracker_idx; tracker_idx2 > referenceTracker_idx; tracker_idx2--) {
            if(trackers[tracker_idx2].toPrevious) {
              centerReferenceTracker.add(trackers[tracker_idx2].toPrevious);
            } else {
              chainIncomplete = true;
              break;
            }
          }
          if(!chainIncomplete) {
            scarf.position.copy(centerReferenceTracker);
            //scarf2.position.copy(centerReferenceTracker);
            scarf2.position.x = scarf2.kfX.filter(centerReferenceTracker.x, 1);
            scarf2.position.y = scarf2.kfY.filter(centerReferenceTracker.y, 1);
            scarf2.position.z = scarf2.kfZ.filter(centerReferenceTracker.z, 1);


            positionDetermined = true;
          }

        }

      } 
    }

    // build up the guiding line connecting all trackers
    if (referenceTracker_idx != null) {
      scarfGeometry.vertices[referenceTracker_idx] = new THREE.Vector3(0,0,0);
      for(var tracker_idx = referenceTracker_idx +1; tracker_idx < params.tracker_cnt; tracker_idx++) {
        if(trackers[tracker_idx].toPrevious){
          scarfGeometry.vertices[tracker_idx] = scarfGeometry.vertices[tracker_idx -1].clone();
          scarfGeometry.vertices[tracker_idx].sub(trackers[tracker_idx].toPrevious);
        }
      }
      for(var tracker_idx = referenceTracker_idx; tracker_idx > 0; tracker_idx--) {
        if(trackers[tracker_idx].toPrevious){
          scarfGeometry.vertices[tracker_idx -1] = scarfGeometry.vertices[tracker_idx].clone();
          scarfGeometry.vertices[tracker_idx -1].add(trackers[tracker_idx].toPrevious);
        }
      }
    }

    // build up the polygon following the line connecting all trackers
    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];

      if(tracker.centerUp) {
        var centerUp = scarfGeometry.vertices[tracker_idx].clone().add(tracker.centerUp);
        var centerDown = scarfGeometry.vertices[tracker_idx].clone().sub(tracker.centerUp);

        if(tracker_idx === 0) {
          var offs = tracker.toPrevious.clone().multiplyScalar(0.8);
          centerUp.add(offs);
          centerDown.add(offs);
        } else if(tracker_idx === params.tracker_cnt -1) {
          var offs = tracker.toPrevious.clone().multiplyScalar(-0.5);
          centerUp.add(offs);
          centerDown.add(offs);
        }


        position.setX(tracker_idx, centerUp.x);
        position.setY(tracker_idx, centerUp.y);
        position.setZ(tracker_idx, centerUp.z);

        position.setX(params.tracker_cnt + tracker_idx, centerDown.x);
        position.setY(params.tracker_cnt + tracker_idx, centerDown.y);
        position.setZ(params.tracker_cnt + tracker_idx, centerDown.z);
      }
      
    }


    if(!positionDetermined && someTrackerActive) referenceTracker_idx = null; // next cycle will reset reference

    // update tracker countdowns
    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      var tracker = trackers[tracker_idx];
      if(tracker.countDown) tracker.countDown--;
    }

  }

  function updateTrackerPose(tracker_idx, R, t, updateRate){

    var tracker = trackers[tracker_idx];

    var factor = 1;

    var video = document.getElementById('video');

    var ratioWindow = window.innerWidth / window.innerHeight;
    var ratioVideo = video.videoWidth / video.videoHeight;

    if(ratioWindow < ratioVideo) factor = window.innerHeight / video.videoHeight; // video is wider than window, since video is on cover use height as reference
    else factor = window.innerWidth / video.videoWidth;

    t = new THREE.Vector3(-t.x * factor, t.y * factor, -t.z * factor);

    var q = new THREE.Quaternion();
    q.fromArray(frame.pose.orientation);
    q.normalize();

    var relativeQ = tracker.latestPose.q.clone().inverse().multiply(q);
    relativeQ = relativeQ.inverse();

    var M2 = new THREE.Matrix4();
    M2.makeRotationFromQuaternion ( relativeQ ) ;

    M2.multiplyMatrices(orientationCorrection, M2);

    var M = new THREE.Matrix4();
    M.fromArray([
      -R.elements[0],  R.elements[1], -R.elements[2],  0,   // row 1
      -R.elements[3],  R.elements[4], -R.elements[5],  0,   // row 2
      R.elements[6], -R.elements[7],  R.elements[8],  0,   // row 3
      //-t.x,   t.y,  -t.z,   1    // row 4
      t.x,   t.y,  t.z,   1    // row 4
    ])

    M.multiplyMatrices(orientationCorrection2, M);

    M.multiplyMatrices(M2, M);

    const x = tracker.kfs.kfX.filter(M.elements[12], updateRate);// * factor;
    const y = tracker.kfs.kfY.filter(M.elements[13], updateRate);// * factor;
    const z = tracker.kfs.kfZ.filter(M.elements[14], updateRate); //* 0.85;

    const r1 = tracker.kfs.kf1.filter(M.elements[0], updateRate);
    const r2 = tracker.kfs.kf2.filter(M.elements[1], updateRate);
    const r3 = tracker.kfs.kf3.filter(M.elements[2], updateRate);
    const r4 = tracker.kfs.kf4.filter(M.elements[4], updateRate);
    const r5 = tracker.kfs.kf5.filter(M.elements[5], updateRate);
    const r6 = tracker.kfs.kf6.filter(M.elements[6], updateRate);
    const r7 = tracker.kfs.kf7.filter(M.elements[8], updateRate);
    const r8 = tracker.kfs.kf8.filter(M.elements[9], updateRate);
    const r9 = tracker.kfs.kf9.filter(M.elements[10], updateRate);

    tracker.matrix.fromArray([
      r1, r2, r3,  0,   // row 1
      r4, r5, r6,  0,   // row 2
      r7, r8, r9,  0,   // row 3
      x,  y,  z,  1    // row 4
    ]);
  }

  this.setTracker = function(tracker_idx, R, t, t2, R2, good, isLandscape){
    const q = new THREE.Quaternion();
    q.fromArray(frame.pose.orientation);
    q.normalize();

    trackers[tracker_idx].latestPose = {
      R: R, 
      t: t,
      q: q,
      updateRate: good
    }

    trackers[tracker_idx].countDown = 5;
    updateTrackerPose(tracker_idx, R, t, good);

    if(referenceTracker_idx == null) {
      referenceTracker_idx = tracker_idx;

      scarf2.kfX = new KalmanFilter(kfOptions_Scarf);
      scarf2.kfY = new KalmanFilter(kfOptions_Scarf);
      scarf2.kfZ = new KalmanFilter(kfOptions_Scarf);

      for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
        var tracker = trackers[tracker_idx];
        tracker.kfXPrev = new KalmanFilter(kfOptions_Scarf);
        tracker.kfYPrev = new KalmanFilter(kfOptions_Scarf);
        tracker.kfZPrev = new KalmanFilter(kfOptions_Scarf);
      }
    } 
  }

  this.setLandscapeInput = function(){

    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
       trackerOrientationGroups[tracker_idx].setRotationFromAxisAngle ( new THREE.Vector3(0,0,1), 0 );
    }
    scarfOrientationGroup.setRotationFromAxisAngle( new THREE.Vector3(0,0,1), 0 );

    trackerDistance = 415

    orientationCorrection.fromArray([
      1,  0,  0,  0,   // row 1
      0,  1,  0,  0,   // row 2
      0,  0,  1,  0,   // row 3
      0,  0,  0,  1    // row 4
    ]);

    orientationCorrection2.fromArray([
      1,  0,  0,  0,   // row 1
      0,  1,  0,  0,   // row 2
      0,  0,  1,  0,   // row 3
      0,  0,  0,  1    // row 4
    ]);
  }

  this.setPortraitInput = function(){

    for(var tracker_idx = 0; tracker_idx < params.tracker_cnt; tracker_idx++) {
      trackerOrientationGroups[tracker_idx].setRotationFromAxisAngle ( new THREE.Vector3(0,0,1), -Math.PI/2 );
    }
    scarfOrientationGroup.setRotationFromAxisAngle( new THREE.Vector3(0,0,1), -Math.PI/2 );

    trackerDistance = 360;

    orientationCorrection.fromArray([
      Math.cos(-Math.PI/2),  -Math.sin(-Math.PI/2),  0,  0,   // row 1
      Math.sin(-Math.PI/2),  Math.cos(-Math.PI/2),  0,  0,   // row 2
      0,  0,  1,  0,   // row 3
      0,  0,  0,  1    // row 4
    ]);

    orientationCorrection2.fromArray([
      Math.cos(Math.PI/2),  -Math.sin(Math.PI/2),  0,  0,   // row 1
      Math.sin(Math.PI/2),  Math.cos(Math.PI/2),  0,  0,   // row 2
      0,  0,  1,  0,   // row 3
      0,  0,  0,  1    // row 4
    ]);
  }

  function onResize() {
    console.log('Resizing to %s x %s.', window.innerWidth, window.innerHeight);
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;

    if(window.innerWidth < window.innerHeight)
      camera.fov = 61;
    else 
      camera.fov = 25;
    camera.updateProjectionMatrix();
  }

  function onVRDisplayPresentChange() {
    console.log('onVRDisplayPresentChange');
    onResize();
  }

  // Resize the WebGL canvas when we resize and also when we change modes.
  window.addEventListener('resize', onResize);
  window.addEventListener('vrdisplaypresentchange', onVRDisplayPresentChange);

  function enterFullscreen (el) {
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    }
  }


}

