import "./styles.scss";

import trackerURL from './marker.jpg'

import "./jquery.min.js";
import jsfeat from "./jsfeat-min.js";
import * as dat from 'dat.gui';
import * as THREE from 'three';
import {EPnP} from "./epnp.js";
import {View3d} from "./view3d.js";
//import {MatchPattern} from "./match_pattern.js"; DEPRECATED - NOT FUNCTIONING AND NO PERFORMANCE INCREASE
import KalmanFilter from 'kalmanjs';

$(window).load(function() {
    "use strict";

    
    
    var initializingNotice = document.getElementById('initializingNotice');
    initializingNotice.classList.remove('hidden');


    
    var omega = 1;



/*
    const gpu = new GPU(
       // {mode: 'cpu'}
       {mode: 'headlessgl'}
        );

*/
/*
    const getRandomInt = (max) => {
      return Math.floor(Math.random() * Math.floor(max));
    }


    const generateMatrices = () => {
        const matrices = [[], []]
        for (let y = 0; y < 512; y++){
          matrices[0].push([])
          matrices[1].push([])
          for (let x = 0; x < 512; x++){
            matrices[0][y].push(getRandomInt(100))
            matrices[1][y].push(getRandomInt(100))
          }
        }
        return matrices
    }


    const multiplyMatrix = gpu.createKernel(function(a, b) {
        var sum = 0;
        for (var i = 0; i < 512; i++) {
          sum += a[this.thread.y][i] + b[i][this.thread.x];
        }
        return sum;
    }, {
        strictIntegers: true
    }).setOutput([512, 512]);
*/

    // http://jsfiddle.net/r21pc9s2/


    const settings = { 
        /*argumentTypes: { 
            a: 'Integer', 
            b: 'Integer'
        },
        returnType: 'Integer',
        precision: 'unsigned',*/
        precision: 'unsigned',
        //strictIntegers: true,
        //fixIntegerDivisionAccuracy: true
        //output: [1]
    };


    let matchKernel = null;

/*
    function xor(a, b) {
        var byteVal = 1;
        var result = 0;
        var n1 = Math.abs(a);
        var n2 = Math.abs(b);
        while (n1 > 0 || n2 > 0) {
            if ((n1 % 2 > 0) != (n2 % 2 > 0)) { result+=byteVal; }
            n1 = Math.floor(n1 / 2);
            n2 = Math.floor(n2 / 2);
            byteVal *= 2;
        }
        if(a < 0 && b >= 0 || b < 0 && a >= 0) result *= -1;
        return result;
    }

    function rshift(n,shift) {
      for (var i=0;i<shift;i++) { n = Math.floor(n / 2); }
      return n;
    }

    function and(n1,n2) {
      var byteVal = 1;
      var result = 0;
      while (n1 > 0 || n2 > 0) {
        if (n1 % 2 > 0 && n2 % 2 > 0) { result+=byteVal; }
        n1 = Math.floor(n1 / 2);
        n2 = Math.floor(n2 / 2);
        byteVal *= 2;
      }
      return result;
    }

    const diffMat = gpu.createKernel(function(a,b) { // compute hamming weight
        let diff = 0;
        let n = 0;
        for (let i = 0; i < 32; i++) {
           /* n = xor(a[this.thread.x][i], b[this.thread.y][i]);
            n = n - and(rshift(n, 1), 0x55);
            n = and(n, 0x33) + and(rshift(n, 2), 0x33);
            diff += (and((n + rshift(n, 4)), 0x0F) * 0x01);
            */
            /*
            n = a[this.thread.x][i] ^ b[this.thread.y][i];
            n = n - ((n >> 1) & 0x55);
            n = (n & 0x33) + ((n >> 2) & 0x33);
            diff += (((n + (n >> 4)) & 0x0F) * 0x01);
            
            //diff += //popcntu8(a[this.thread.x][i] ^ b[this.thread.y][i])
        }
        return diff;
    }, settings)
    .setOutput([500,300]) // 500 cols 300 rows // this.thread.x is col index is screen // this.thread.y is row index is target
    .setFunctions([xor,rshift,and]);


    try {

        const diffMat0 = gpu.createKernel(function(a,b) { // compute hamming weight
            let diff = 0;
            for (let i = 0; i < 32; i++) {
                diff += popcntu8(a[this.thread.x][i] ^ b[this.thread.y][i])
            }
            return diff;
        }, settings)
        .setOutput([500,300]) // 500 cols 300 rows // this.thread.x is col index is screen // this.thread.y is row index is target
        .setFunctions([popcntu8]);

        const diffMat1 = gpu.createKernel(function(a,b) { // compute hamming weight
            let diff = 0;
            for (let i = 0; i < 32; i++) {
                diff += popcntu8(a[this.thread.x][i] ^ b[this.thread.y][i])
            }
            return diff;
        }, settings)
        .setOutput([500,300]) // 500 cols 300 rows // this.thread.x is col index is screen // this.thread.y is row index is target
        .setFunctions([popcntu8]);

        const diffMat2 = gpu.createKernel(function(a,b) { // compute hamming weight
            let diff = 0;
            for (let i = 0; i < 32; i++) {
                diff += popcntu8(a[this.thread.x][i] ^ b[this.thread.y][i])
            }
            return diff;
        }, settings)
        .setOutput([500,300]) // 500 cols 300 rows // this.thread.x is col index is screen // this.thread.y is row index is target
        .setFunctions([popcntu8]);

        const diffMat3 = gpu.createKernel(function(a,b) { // compute hamming weight
            let diff = 0;
            for (let i = 0; i < 32; i++) {
                diff += popcntu8(a[this.thread.x][i] ^ b[this.thread.y][i])
            }
            return diff;
        }, settings)
        .setOutput([500,300]) // 500 cols 300 rows // this.thread.x is col index is screen // this.thread.y is row index is target
        .setFunctions([popcntu8]);

        const bestDiff = gpu.createKernel(function(a, b, c, d) {
            let bestDiff = this.constants.matchThreshold;
            let bestIdx = -1;
            let bestLev = -1;

            for (let i = 0; i < 300; i++) {
                let curDiff = a[i][this.thread.x]; // this.thread.x is screen
                if (curDiff < bestDiff) {
                    bestDiff = curDiff;
                    bestIdx = i;
                    bestLev = 0;
                }           
     
                curDiff = b[i][this.thread.x]; // this.thread.x is screen
                if (curDiff < bestDiff) {
                    bestDiff = curDiff;
                    bestIdx = i;
                    bestLev = 1;
                }

                curDiff = c[i][this.thread.x]; // this.thread.x is screen
                if (curDiff < bestDiff) {
                    bestDiff = curDiff;
                    bestIdx = i;
                    bestLev = 2;
                }

                curDiff = d[i][this.thread.x]; // this.thread.x is screen
                if (curDiff < bestDiff) {
                    bestDiff = curDiff;
                    bestIdx = i;
                    bestLev = 3;
                }          
            }

            return bestIdx + 1000*bestLev;
        }, settings).setOutput([500])
        .setConstants({ matchThreshold: 48 }); // screen index

        matchKernel = gpu.combineKernels(diffMat0, diffMat1, diffMat2, diffMat3, bestDiff, function(a, b, c, d, e) {
            return bestDiff(diffMat0(a, b), diffMat1(a, c), diffMat2(a, d), diffMat3(a, e));
        });

    } catch(err) {
        alert(err);
    }
*/
/*
    if(best_dist < options.match_threshold) {
                matches[num_matches].screen_idx = qidx;
                matches[num_matches].pattern_lev = best_lev;
                matches[num_matches].pattern_idx = best_idx;
                num_matches++;
            }
*/
/**/
    // 352983
    /*
    function popcnt32(n) {
        n -= ((n >> 1) & 0x55555555);
        n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
        return (((n + (n >> 4))& 0xF0F0F0F)* 0x1010101) >> 24;
    }
    */

/*
int NumberOfSetBits( uint8_t b )
{
     b = b - ((b >> 1) & 0x55);
     b = (b & 0x33) + ((b >> 2) & 0x33);
     return (((b + (b >> 4)) & 0x0F) * 0x01);
}
*/
/*
    function popcnt322(n) {
        n -= ((n >> 1) & 0x55555555);
        n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
        return (((n + (n >> 4))& 0xF0F0F0F)* 0x1010101) >> 24;
    }


    console.log(39467269 ^ -213999678, popcnt322(39467269 ^ -213999678));

    console.log(
        diff(5, 194),
        diff(57, 159),
        diff(90, 62),
        diff(2, 243)
    );
*/


    // 677969336
    // 184, 253, 104, 40

    // -145289879
    // 105, 13, 87, 247




   // const matrices = generateMatrices()
   // const out = multiplyMatrix(matrices[0], matrices[1])

   

   

    // TRACKING ---

    var n = 30;
	//var uc = videoWidth/2;
	//var vc = videoHeight/2; 
	var uc = 0; 
	var vc = 0;
	//var fu = 1100; 
    //var uc = 640/2; 
    //var vc = 480/2;
	//var fv = 1300;

    // honor
    var fu = -265; 
    var fv = -265;

   

    fu = -280; 
    fv = -280;
/*
    fu = -120; 
    fv = -120;
*/
/*
    fu = -800; 
    fv = -800;
*/
	// 268 100
	var PnP = new EPnP({
		uc: uc, 
		vc: vc, 
		fu: fu, 
		fv: fv
	});

	PnP.set_maximum_number_of_correspondences(n);








    var tracker_cnt = 8;
	var trackedElement = document.getElementById('trackedElement');


    var view3d ;

    //var Match = new MatchPattern({tracker_cnt: tracker_cnt});

	// our point match structure
    var match_t = (function () {
        function match_t(screen_idx, pattern_lev, pattern_idx, distance) {
            if (typeof screen_idx === "undefined") { screen_idx=0; }
            if (typeof pattern_lev === "undefined") { pattern_lev=0; }
            if (typeof pattern_idx === "undefined") { pattern_idx=0; }
            if (typeof distance === "undefined") { distance=0; }

            this.screen_idx = screen_idx;
            this.pattern_lev = pattern_lev;
            this.pattern_idx = pattern_idx;
            this.distance = distance;
        }
        return match_t;
    })();

    var gui,options,ctx;
    var img_u8, img_u8_smooth, screen_corners, num_corners, screen_descriptors;
    var pattern_corners = [], pattern_descriptors  = [], pattern_preview  = [];
    var matches = [], homo3x3 = [], match_mask = [];
    
    


    var num_train_levels = 9;

    var curr_tracker_lvl = [];
    var curr_tracker_lvl_mov = [];
    for(var tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++){
        curr_tracker_lvl[tracker_idx] = num_train_levels -1; // start from smallest (far)
        curr_tracker_lvl_mov[tracker_idx] = -1; // search to largest (close)
    }
    var curr_main_tracker = -1;
    var min_tracker_d = 1000000;
    var main_tracker_timeout = 0;

    var video = document.getElementById('video');
    var canvas = document.getElementById('canvasVideoGrabber');
    var ar = document.getElementById('ar');


    var opt = function(){
        this.blur_size = 8; // 5
        this.lap_thres = 30;
        this.eigen_thres = 25;
        this.match_threshold = 48;

        this.train_pattern = function(tracker_idx, tracker_cnt) {
			set_pattern(tracker_idx, tracker_cnt, function(img_u8){

                var lev=0, i=0;
                var sc = 1.0;
                var max_pattern_size = 512;
                var max_per_level = 200;
                //var sc_inc = 2; //Math.sqrt(2.0); // magic number ;)
                var sc_dec = 0.8;
                var lev0_img = new jsfeat.matrix_t(img_u8.cols, img_u8.rows, jsfeat.U8_t | jsfeat.C1_t);
                var lev_img = new jsfeat.matrix_t(img_u8.cols, img_u8.rows, jsfeat.U8_t | jsfeat.C1_t);
                var new_width=0, new_height=0;
                var lev_corners, lev_descr;
                var corners_num=0;

                var sc0 = Math.min(max_pattern_size/img_u8.cols, max_pattern_size/img_u8.rows);
                new_width = (img_u8.cols*sc0)|0;
                new_height = (img_u8.rows*sc0)|0;

                jsfeat.imgproc.resample(img_u8, lev0_img, new_width, new_height);

                // prepare preview
                pattern_preview[tracker_idx] = new jsfeat.matrix_t(new_width>>1, new_height>>1, jsfeat.U8_t | jsfeat.C1_t);
                jsfeat.imgproc.pyrdown(lev0_img, pattern_preview[tracker_idx]);

                for(lev=0; lev < num_train_levels; ++lev) {
                    pattern_corners[tracker_idx][lev] = [];
                    lev_corners = pattern_corners[tracker_idx][lev];

                    // preallocate corners array
                    i = (new_width*new_height) >> lev;
                    while(--i >= 0) {
                        lev_corners[i] = new jsfeat.keypoint_t(0,0,0,0,-1);
                    }

                    pattern_descriptors[tracker_idx][lev] = new jsfeat.matrix_t(32, max_per_level, jsfeat.U8_t | jsfeat.C1_t);
                }

                // do the first level
                lev_corners = pattern_corners[tracker_idx][0];
                lev_descr = pattern_descriptors[tracker_idx][0];

                jsfeat.imgproc.gaussian_blur(lev0_img, lev_img, options.blur_size|0); // this is more robust
                corners_num = detect_keypoints(lev_img, lev_corners, max_per_level);
                jsfeat.orb.describe(lev_img, lev_corners, corners_num, lev_descr);

                console.log("train " + lev_img.cols + "x" + lev_img.rows + " points: " + corners_num);

                //sc /= sc_inc;
                sc *= sc_dec;

                // lets do multiple scale levels
                // we can use Canvas context draw method for faster resize
                // but its nice to demonstrate that you can do everything with jsfeat
                for(lev = 1; lev < num_train_levels; ++lev) {
                    lev_corners = pattern_corners[tracker_idx][lev];
                    lev_descr = pattern_descriptors[tracker_idx][lev];

                    new_width = (lev0_img.cols*sc)|0;
                    new_height = (lev0_img.rows*sc)|0;

                    jsfeat.imgproc.resample(lev0_img, lev_img, new_width, new_height);
                    jsfeat.imgproc.gaussian_blur(lev_img, lev_img, options.blur_size|0);
                    corners_num = detect_keypoints(lev_img, lev_corners, max_per_level);
                    jsfeat.orb.describe(lev_img, lev_corners, corners_num, lev_descr);

                    // fix the coordinates due to scale level
                    for(i = 0; i < corners_num; ++i) {
                        lev_corners[i].x *= 1./sc;
                        lev_corners[i].y *= 1./sc;
                    }

                    console.log("train " + lev_img.cols + "x" + lev_img.rows + " points: " + corners_num);

                    //sc /= sc_inc;
                     sc *= sc_dec;
                }

                initializingNotice.classList.add('hidden');

            });
        };
    }

    function app(videoWidth, videoHeight, tracker_cnt) {
        canvas.width = 320*omega;
        canvas.height = 240*omega;
        ctx = canvas.getContext('2d', { alpha: false });

        ctx.fillStyle = "rgb(0,255,0)";
        ctx.strokeStyle = "rgb(0,255,0)";

        img_u8 = new jsfeat.matrix_t(320*omega, 240*omega, jsfeat.U8_t | jsfeat.C1_t);
        img_u8_smooth = new jsfeat.matrix_t(320*omega, 240*omega, jsfeat.U8_t | jsfeat.C1_t);
        screen_descriptors = new jsfeat.matrix_t(32, 1000, jsfeat.U8_t | jsfeat.C1_t);

        screen_corners = [];

        for(var tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {
            pattern_descriptors[tracker_idx] = [];
            pattern_corners[tracker_idx] = [];
            matches[tracker_idx] = [];
        }
        
        var i = 320*240*omega*omega;
        while(--i >= 0) {
            screen_corners[i] = new jsfeat.keypoint_t(0,0,0,0,-1);
            for(var tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {
                matches[tracker_idx][i] = new match_t();
            }
        }

        // transform matrix
        for(var tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {
            homo3x3[tracker_idx] = new jsfeat.matrix_t(3,3,jsfeat.F32C1_t);
            match_mask[tracker_idx] = new jsfeat.matrix_t(1000,1,jsfeat.U8C1_t);
        }

        options = new opt();

        for(var tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++)
            options.train_pattern(tracker_idx, tracker_cnt);

        view3d = new View3d({tracker_cnt: tracker_cnt, videoWidth: videoWidth, videoHeight: videoHeight});
    }

    function tick() {

       window.setTimeout(()=>{
             
       

        if (video.readyState === video.HAVE_ENOUGH_DATA) {

            //ctx.clearRect(0,0,320*omega, 240*omega);

            if(video.videoHeight < video.videoWidth) {
                ctx.drawImage(video, 
                    0, 0, video.videoWidth, video.videoHeight, //video.videoWidth, video.videoHeight // src
                    0, 0, 320*omega, 240*omega // dest*/
                    );

                view3d.setLandscapeInput();
                 //ar.style.transform = 'rotateX(-90deg)';
            } else {

                ctx.translate(0, 240*omega);
                ctx.rotate(-Math.PI/2);
                ctx.drawImage(video, 
                    0, 0, video.videoWidth, video.videoHeight, //video.videoWidth, video.videoHeight // src
                    0, 0, 240*omega, 320*omega // dest*/
                    );
                ctx.rotate(Math.PI/2);
                ctx.translate(0, -240*omega);
                ar.style.transform = 'translateY(-100%) rotate(90deg)';

                view3d.setPortraitInput();
            }
            
            // create screen descriptors
            var imageData = ctx.getImageData(0, 0, 320*omega, 240*omega);
            jsfeat.imgproc.grayscale(imageData.data, 320*omega, 240*omega, img_u8);
            jsfeat.imgproc.gaussian_blur(img_u8, img_u8_smooth, options.blur_size|0);
            jsfeat.yape06.laplacian_threshold = options.lap_thres|0;
            jsfeat.yape06.min_eigen_value_threshold = options.eigen_thres|0;
            num_corners = detect_keypoints(img_u8_smooth, screen_corners, 500);
            jsfeat.orb.describe(img_u8_smooth, screen_corners, num_corners, screen_descriptors);

            // render result back to canvas
            var data_u32 = new Uint32Array(imageData.data.buffer);
            render_corners(screen_corners, num_corners, data_u32, 320*omega);

            //sensors.read();


            if(pattern_preview.length != tracker_cnt) { // trackes have not been loaded so far

                requestAnimationFrame(tick);

            } else {
                match_patterns(function(all_num_matches){

                    if(main_tracker_timeout) {
                        main_tracker_timeout--;
                    } else {
                        curr_main_tracker = -1;
                        min_tracker_d = 1000000;
                    }
                   
                    
                    {
                        

                        var tracker_idx = 0;

                        // render pattern and matches
                        var num_matches = 0;
                        var good_matches = 0;
                
                        if(pattern_preview[tracker_idx]) {
                            //render_mono_image(pattern_preview[tracker_idx].data, data_u32, pattern_preview[tracker_idx].cols, pattern_preview[tracker_idx].rows, 320);
                            num_matches = all_num_matches[tracker_idx];
                            good_matches = find_transform(num_matches, tracker_idx);
                        }

                        ctx.putImageData(imageData, 0, 0);

                        if(num_matches) {
                            //render_matches(ctx, num_matches, tracker_idx);
                            epnp(ctx, num_matches, good_matches, tracker_idx);

                            if(good_matches > 8)
                                render_pattern_shape(ctx, tracker_idx);
                        } else {
                            curr_tracker_lvl[0] = (curr_tracker_lvl[0] + curr_tracker_lvl_mov[0]) % num_train_levels;

                        }
                    }



                    for(var tracker_idx = 1; tracker_idx < pattern_descriptors.length; tracker_idx++) {
                        var num_matches = 0;
                        var good_matches = 0;
                        if(pattern_preview[tracker_idx]) {
                            num_matches = all_num_matches[tracker_idx];
                            good_matches = find_transform(num_matches, tracker_idx);
                        }
                        if(num_matches) {
                            epnp(ctx, num_matches, good_matches, tracker_idx);

                            if(good_matches > 8)
                                render_pattern_shape(ctx, tracker_idx);
                        } else {
                            curr_tracker_lvl[tracker_idx] = (curr_tracker_lvl[tracker_idx] + curr_tracker_lvl_mov[tracker_idx]) % num_train_levels;
                        }
                    }

                    console.log("curr_main_tracker", curr_main_tracker);

                    requestAnimationFrame(tick);

                });

            } 
            

        } else requestAnimationFrame(tick);

        }, 0);
    }

    // UTILITIES

    function detect_keypoints(img, corners, max_allowed) {
        // detect features
        var count = jsfeat.yape06.detect(img, corners, 17);

        // sort by score and reduce the count if needed
        if(count > max_allowed) {
            jsfeat.math.qsort(corners, 0, count-1, function(a,b){return (b.score<a.score);});
            count = max_allowed;
        }

        // calculate dominant orientation for each keypoint
        for(var i = 0; i < count; ++i) {
            corners[i].angle = ic_angle(img, corners[i].x, corners[i].y);
        }

        return count;
    }

    // central difference using image moments to find dominant orientation
    var u_max = new Int32Array([15,15,15,15,14,14,14,13,13,12,11,10,9,8,6,3,0]);
    function ic_angle(img, px, py) {
        var half_k = 15; // half patch size
        var m_01 = 0, m_10 = 0;
        var src=img.data, step=img.cols;
        var u=0, v=0, center_off=(py*step + px)|0;
        var v_sum=0,d=0,val_plus=0,val_minus=0;

        // Treat the center line differently, v=0
        for (u = -half_k; u <= half_k; ++u)
            m_10 += u * src[center_off+u];

        // Go line by line in the circular patch
        for (v = 1; v <= half_k; ++v) {
            // Proceed over the two lines
            v_sum = 0;
            d = u_max[v];
            for (u = -d; u <= d; ++u) {
                val_plus = src[center_off+u+v*step];
                val_minus = src[center_off+u-v*step];
                v_sum += (val_plus - val_minus);
                m_10 += u * (val_plus + val_minus);
            }
            m_01 += v * v_sum;
        }

        return Math.atan2(m_01, m_10);
    }

    // estimate homography transform between matched points
    function find_transform(count, tracker_idx) {
        // motion kernel
        var mm_kernel = new jsfeat.motion_model.homography2d();
        // ransac params
        var num_model_points = 4;
        var reproj_threshold = 3;
        var ransac_param = new jsfeat.ransac_params_t(num_model_points,
                                                      reproj_threshold, 0.5, 0.99);

        var pattern_xy = [];
        var screen_xy = [];

        // construct correspondences
        for(var i = 0; i < count; ++i) {
            var m = matches[tracker_idx][i];
            var s_kp = screen_corners[m.screen_idx];
            var p_kp = pattern_corners[tracker_idx][m.pattern_lev][m.pattern_idx];
            pattern_xy[i] = {"x":p_kp.x, "y":p_kp.y};
            screen_xy[i] =  {"x":s_kp.x, "y":s_kp.y};
        }

        // estimate motion
        var ok = false;
        ok = jsfeat.motion_estimator.ransac(ransac_param, mm_kernel,
                                            pattern_xy, screen_xy, count, homo3x3[tracker_idx], match_mask[tracker_idx], 1000);

        // extract good matches and re-estimate
        var good_cnt = 0;
        if(ok) {
            for(var i=0; i < count; ++i) {
                if(match_mask[tracker_idx].data[i]) {
                    pattern_xy[good_cnt].x = pattern_xy[i].x;
                    pattern_xy[good_cnt].y = pattern_xy[i].y;
                    screen_xy[good_cnt].x = screen_xy[i].x;
                    screen_xy[good_cnt].y = screen_xy[i].y;
                    good_cnt++;
                }
            }
            // run kernel directly with inliers only
            mm_kernel.run(pattern_xy, screen_xy, homo3x3[tracker_idx], good_cnt);
        } else {
            jsfeat.matmath.identity_3x3(homo3x3[tracker_idx], 1.0);
        }

        return good_cnt;
    }

    // non zero bits count
    function popcnt32(n) { // int32
        n -= ((n >> 1) & 0x55555555);
        n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
        return (((n + (n >> 4))& 0xF0F0F0F)* 0x1010101) >> 24;
    }
    function popcntu8(n) // uint82
    {
         n = n - ((n >> 1) & 0x55);
         n = (n & 0x33) + ((n >> 2) & 0x33);
         return (((n + (n >> 4)) & 0x0F) * 0x01);
    }

    function set_pattern(tracker_idx, tracker_cnt, callback) {
    	const canvasMarker = document.getElementById('canvasMarker');
		const contextMarker = canvasMarker.getContext('2d', { alpha: false });

    	const image = new Image(1, 1);
      	image.onload = function(){
	        canvasMarker.width = this.naturalWidth/this.naturalHeight * 480;
	        canvasMarker.height = 480;
	        contextMarker.drawImage(this, 0, 0, canvasMarker.width, canvasMarker.height);

            var offset;
            /*if(tracker_idx == 0) offset = 0;
            else if(tracker_idx == tracker_cnt -1) offset = canvasMarker.width - 320;
            else if(tracker_cnt > 2) 
*/

            var w = this.naturalWidth/this.naturalHeight * 480;
            
            offset = Math.round((w / tracker_cnt) * tracker_idx); //(canvasMarker.width - 320) / (tracker_cnt -1) * tracker_idx;

            console.log('Tracker distance', w / tracker_cnt, 640, (w / tracker_cnt) / 640, canvasMarker.width, canvasMarker.height);

	        var imageData = contextMarker.getImageData(offset, 0, 640, 480);
	        var img_u8 = new jsfeat.matrix_t(640, 480, jsfeat.U8_t | jsfeat.C1_t);
	        jsfeat.imgproc.grayscale(imageData.data, 640, 480, img_u8);

	        callback(img_u8);
        };
        image.src = trackerURL;
    }

    function match_patterns(callback) {

        /*
        if(Match.gpuAvailable) { // gl1 27% gl2 10%  cpu 16,5% 
            

            Match.setTexture(0, screen_descriptors.data, 32, 500, false);

            let texture_idx = 1;
            for(let tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {
                Match.setTexture(texture_idx++, pattern_descriptors[tracker_idx][0].data, 32, 300, true);
                Match.setTexture(texture_idx++, pattern_descriptors[tracker_idx][1].data, 32, 300, true);
                Match.setTexture(texture_idx++, pattern_descriptors[tracker_idx][2].data, 32, 300, true);
                Match.setTexture(texture_idx++, pattern_descriptors[tracker_idx][3].data, 32, 300, true);
            }

            Match.drawScene(function(result){
                let all_num_matches = [];

                let off = 0;
                for(let tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {
                    var num_matches = 0;
                    

                        
                        for(let qidx = 0; qidx < 500; ++qidx) {
                            var best_dist = options.match_threshold;
                            var best_idx = -1;
                            var best_lev = -1;

                            var i = qidx * 4 + off;

                            best_idx = result[i];
                            best_lev = result[i + 1];
                            best_dist = result[i + 2];

                            if(best_dist < options.match_threshold) {
                               matches[tracker_idx][num_matches].screen_idx = qidx;
                               matches[tracker_idx][num_matches].pattern_lev = best_lev;
                               matches[tracker_idx][num_matches].pattern_idx = best_idx;

                               num_matches++;
                            }
                        }

                        all_num_matches.push(num_matches);


                    off += 500 * 4;
                    //break;
                }


                callback(all_num_matches);

            });

            

        } else { // 32 ... 
*/

           setTimeout(function(){

                let all_num_matches = [];


                var tracker_start;
                var tracker_end;
                if(curr_main_tracker === -1) {
                    tracker_start = 0;
                    tracker_end = tracker_cnt -1;
                } else {
                    tracker_start = Math.max(0, curr_main_tracker -1);
                    tracker_end = Math.min(tracker_cnt -1, curr_main_tracker +1);

                }

               // console.log(tracker_start, tracker_end);

                for(let tracker_idx = 0; tracker_idx < tracker_cnt; tracker_idx++) {

                    if(tracker_idx >= tracker_start && tracker_idx <= tracker_end) {

                        var q_cnt = screen_descriptors.rows;
                        var query_i32 = screen_descriptors.buffer.i32; // cast to integer buffer
                        var qd_off = 0;
                        var qidx=0,lev=0,pidx=0,k=0,l=0;
                        var num_matches = 0;

                        var lev_start = Math.max(0, curr_tracker_lvl[tracker_idx] -1);
                        var lev_end = Math.min(num_train_levels -1, curr_tracker_lvl[tracker_idx] +1);
                       // console.warn(lev_start, lev_end);

                        for(qidx = 0; qidx < q_cnt; ++qidx) { // go through all 500 feature points of screen_descriptor
                            var best_dist = options.match_threshold;
                            var best_idx = -1;
                            var best_lev = -1;

                            for(lev = lev_start; lev <= lev_end; ++lev) { // go through all training levels
                                var lev_descr = pattern_descriptors[tracker_idx][lev];
                                var ld_cnt = lev_descr.rows;
                                var ld_i32 = lev_descr.buffer.i32; // cast to integer buffer
                                var ld_off = 0;

                                for(pidx = 0; pidx < ld_cnt; ++pidx) { // go through all 300 feature points of training level
                                    var curr_d = 0;
                                    // our descriptor is 32 bytes so we have 8 Integers
                                    for(k=0; k < 8; ++k) {
                                        curr_d += popcnt32( query_i32[qd_off+k]^ld_i32[ld_off+k] );
                                        if(curr_d >= options.match_threshold) break;
                                    }

                                    if(curr_d < best_dist) {
                                        best_dist = curr_d;
                                        best_lev = lev;
                                        best_idx = pidx;
                                    } 

                                    ld_off += 8; // next descriptor
                                }
                            }

                            // filter out by some threshold
                            if(best_dist < options.match_threshold) {
                               matches[tracker_idx][num_matches].screen_idx = qidx;
                                matches[tracker_idx][num_matches].pattern_lev = best_lev;
                                matches[tracker_idx][num_matches].pattern_idx = best_idx;

                                num_matches++;
                            }

                            qd_off += 8; // next query descriptor
                        }

                        all_num_matches.push(num_matches);

                    } else {
                        all_num_matches.push(0);
                    }

                    
                    //console.warn(num_matches);
                }

                callback(all_num_matches);

            }, 0);
        //}

        
       // return all_num_matches;
        
    }


    /* 
    function match_pattern(tracker_idx) {
        var q_cnt = screen_descriptors.rows;
        var query_i32 = screen_descriptors.buffer.i32; // cast to integer buffer
        var qd_off = 0;
        var qidx=0,lev=0,pidx=0,k=0,l=0;
        var num_matches = 0;

        //console.log(Match.gpuAvailable);

        if(Match.gpuAvailable) { // gl1 27% gl2 10%  cpu 16,5% 

            Match.setTexture(0, screen_descriptors.data, 32, 500, false);

            Match.setTexture(1, pattern_descriptors[tracker_idx][0].data, 32, 300, true);
            Match.setTexture(2, pattern_descriptors[tracker_idx][1].data, 32, 300, true);
            Match.setTexture(3, pattern_descriptors[tracker_idx][2].data, 32, 300, true);
            Match.setTexture(4, pattern_descriptors[tracker_idx][3].data, 32, 300, true);

            var result = Match.drawScene();

            for(qidx = 0; qidx < 500; ++qidx) {
                var best_dist = options.match_threshold;
                var best_idx = -1;
                var best_lev = -1;

                best_idx = result[qidx * 4];
                best_lev = result[qidx * 4 + 1];
                best_dist = result[qidx * 4 + 2];

                if(best_dist < options.match_threshold) {
                   matches[tracker_idx][num_matches].screen_idx = qidx;
                   matches[tracker_idx][num_matches].pattern_lev = best_lev;
                   matches[tracker_idx][num_matches].pattern_idx = best_idx;

                   num_matches++;
                }
            }

            return num_matches;

        } else { // 32 ... 

            for(qidx = 0; qidx < q_cnt; ++qidx) { // go through all 500 feature points of screen_descriptor
                var best_dist = options.match_threshold;
                var best_idx = -1;
                var best_lev = -1;

                for(lev = 0; lev < num_train_levels; ++lev) { // go through all training levels
                    var lev_descr = pattern_descriptors[tracker_idx][lev];
                    var ld_cnt = lev_descr.rows;
                    var ld_i32 = lev_descr.buffer.i32; // cast to integer buffer
                    var ld_off = 0;

                    for(pidx = 0; pidx < ld_cnt; ++pidx) { // go through all 300 feature points of training level
                        var curr_d = 0;
                        // our descriptor is 32 bytes so we have 8 Integers
                        for(k=0; k < 8; ++k) {
                            curr_d += popcnt32( query_i32[qd_off+k]^ld_i32[ld_off+k] );
                            if(curr_d >= options.match_threshold) break;
                        }

                        if(curr_d < best_dist) {
                            best_dist = curr_d;
                            best_lev = lev;
                            best_idx = pidx;
                        } 

                        ld_off += 8; // next descriptor
                    }
                }

                // filter out by some threshold
                if(best_dist < options.match_threshold) {
                   matches[tracker_idx][num_matches].screen_idx = qidx;
                    matches[tracker_idx][num_matches].pattern_lev = best_lev;
                    matches[tracker_idx][num_matches].pattern_idx = best_idx;

                    num_matches++;
                }

                qd_off += 8; // next query descriptor
            }

            return num_matches;

        }
    }
    */


    // project/transform rectangle corners with 3x3 Matrix
    function tCorners(M, w, h) {
        var pt = [ {'x':0,'y':0}, {'x':w,'y':0}, {'x':w,'y':h}, {'x':0,'y':h} ];
        var z=0.0, i=0, px=0.0, py=0.0;

        for (; i < 4; ++i) {
            px = M[0]*pt[i].x + M[1]*pt[i].y + M[2];
            py = M[3]*pt[i].x + M[4]*pt[i].y + M[5];
            z = M[6]*pt[i].x + M[7]*pt[i].y + M[8];
            pt[i].x = px/z;
            pt[i].y = py/z;
        }

        return pt;
    }

    function render_matches(ctx, count, tracker_idx) {

        for(var i = 0; i < count; ++i) {
            var m = matches[tracker_idx][i];
            var s_kp = screen_corners[m.screen_idx];
            var p_kp = pattern_corners[tracker_idx][m.pattern_lev][m.pattern_idx];
            if(match_mask[tracker_idx].data[i]) {
                ctx.strokeStyle = "rgb(0,255,0)";
            } else {
            	continue;
                ctx.strokeStyle = "rgb(255,0,0)";
            }
            ctx.beginPath();
            ctx.moveTo(s_kp.x,s_kp.y);
            ctx.lineTo(p_kp.x*0.5, p_kp.y*0.5); // our preview is downscaled
            ctx.lineWidth=1;
            ctx.stroke();
        }
    }

    var kfOptions = {
        R: 10.0,
        Q: 15.0,
        A: 1.0
    }

    var kfX = new KalmanFilter(kfOptions);
    var kfY = new KalmanFilter(kfOptions);
    var kfZ = new KalmanFilter(kfOptions);


    var kfOptions2 = {
        R: 0.1,
        Q: 2.0,
        A: 1.0
    }

    var kf1 = new KalmanFilter(kfOptions2);
    var kf2 = new KalmanFilter(kfOptions2);
    var kf3 = new KalmanFilter(kfOptions2);
    var kf4 = new KalmanFilter(kfOptions2);
    var kf5 = new KalmanFilter(kfOptions2);
    var kf6 = new KalmanFilter(kfOptions2);
    var kf7 = new KalmanFilter(kfOptions2);
    var kf8 = new KalmanFilter(kfOptions2);
    var kf9 = new KalmanFilter(kfOptions2);





    function epnp(ctx, count, good_matches, tracker_idx) {

    	if(good_matches > 10) {
        //if(good_matches > 8) {
    		PnP.reset_correspondences();

            var w = 160 * omega;
            var h = 120 * omega;

            /*
            var shape_pts = tCorners(homo3x3[tracker_idx].data, pattern_preview[tracker_idx].cols*2, pattern_preview[tracker_idx].rows*2);
            PnP.add_correspondence(
                new THREE.Vector3(-pattern_preview[tracker_idx].cols,
                    -pattern_preview[tracker_idx].rows, 
                    0
                    ), 
                new THREE.Vector2(
                    shape_pts[0].x - w, 
                    shape_pts[0].y - h
                    )
            );
            // upper right
            PnP.add_correspondence(
                new THREE.Vector3(
                    pattern_preview[tracker_idx].cols, 
                    -pattern_preview[tracker_idx].rows, 
                    0), 
                new THREE.Vector2(
                    shape_pts[1].x - w, 
                    shape_pts[1].y - h
                    )
            );
            // lower right
            PnP.add_correspondence(
                new THREE.Vector3(
                    pattern_preview[tracker_idx].cols, 
                    pattern_preview[tracker_idx].rows, 
                    0), 
                new THREE.Vector2(
                    shape_pts[2].x - w, 
                    shape_pts[2].y - h
                    )
            );
            // lower left
            PnP.add_correspondence(
                new THREE.Vector3(
                    -pattern_preview[tracker_idx].cols, 
                    pattern_preview[tracker_idx].rows, 
                    0), 
                new THREE.Vector2(
                    shape_pts[3].x - w, 
                    shape_pts[3].y - h
                    )
            );
            */
            
            //console.log(pattern_preview[tracker_idx].cols, pattern_preview[tracker_idx].rows);

            var tracker_lvl_mean = 0;

            for(var i = 0; i < count; ++i) {
                var m = matches[tracker_idx][i];
                if(match_mask[tracker_idx].data[i]) {

                    tracker_lvl_mean += m.pattern_lev;

                    //console.log(m);
                	var s_kp = screen_corners[m.screen_idx];
                    var p_kp = pattern_corners[tracker_idx][m.pattern_lev][m.pattern_idx];
                    //console.log(p_kp.x -pattern_preview[tracker_idx].cols, -pattern_preview[tracker_idx].cols, pattern_preview[tracker_idx].cols)
                    //console.log(s_kp.x - w, shape_pts[0].x - w, shape_pts[1].x - w, shape_pts[2].x - w, shape_pts[3].x - w)

  
                        PnP.add_correspondence(
                            new THREE.Vector3(
                                p_kp.x - pattern_preview[tracker_idx].cols, 
                                p_kp.y - pattern_preview[tracker_idx].rows,
                                 0), 
                            new THREE.Vector2(
                                s_kp.x - w, 
                                s_kp.y - h 
                                )
                            );


		          	
                }   
            }

            tracker_lvl_mean /= good_matches;

            var tracker_lvl_mean_int = Math.round(tracker_lvl_mean);

            curr_tracker_lvl_mov[tracker_idx] = tracker_lvl_mean < curr_tracker_lvl[tracker_idx] ? -1 : 1;

            curr_tracker_lvl[tracker_idx] = tracker_lvl_mean_int;

            //console.log(curr_tracker_lvl_mov[tracker_idx]);

         //   console.log(tracker_lvl_mean_int);
           // console.log("----");
            try {

            var {R_est, t_est, err} = PnP.compute_pose();

            //var good = err < 0.8 && good_matches > 8 ? 1 : 0;
            var good = 100.0 - (100.0 / good_matches);

            //console.log(err);
            //console.log(t_est);

           // t_est.multiplyScalar(1.5);

            const xFiltered = kfX.filter(t_est.x, good);
            const yFiltered = kfY.filter(t_est.y, good);
            const zFiltered = kfZ.filter(t_est.z, good);




            const r1 = kf1.filter(R_est.elements[0], good);
            const r2 = kf2.filter(R_est.elements[1], good);
            const r3 = kf3.filter(R_est.elements[2], good);
            const r4 = kf4.filter(R_est.elements[3], good);
            const r5 = kf5.filter(R_est.elements[4], good);
            const r6 = kf6.filter(R_est.elements[5], good);
            const r7 = kf7.filter(R_est.elements[6], good);
            const r8 = kf8.filter(R_est.elements[7], good);
            const r9 = kf9.filter(R_est.elements[8], good);


            const t_est2 = new THREE.Vector3(xFiltered, yFiltered, zFiltered);

            //console.log(xFiltered, yFiltered, zFiltered);

            const R_est2 = new THREE.Matrix3();

            R_est2.elements[0] = r1;
            R_est2.elements[1] = r2;
            R_est2.elements[2] = r3;
            R_est2.elements[3] = r4;
            R_est2.elements[4] = r5;
            R_est2.elements[5] = r6;
            R_est2.elements[6] = r7;
            R_est2.elements[7] = r8;
            R_est2.elements[8] = r9;


/*
            const r0 = kf0.filter(R_est.elements[0]);
            const r1 = kf1.filter(R_est.elements[1]);
            const r2 = kf2.filter(R_est.elements[2]);
            const r3 = kf3.filter(R_est.elements[3]);
            const r4 = kf4.filter(R_est.elements[4]);
            const r5 = kf5.filter(R_est.elements[5]);
            const r6 = kf6.filter(R_est.elements[6]);
            const r7 = kf7.filter(R_est.elements[7]);
            const r8 = kf8.filter(R_est.elements[8]);


            var matrix3d1 = "translate(-50%, -50%) matrix3d("
              + (-r0) + "," + (-r1) + ","+ (-r2) +",0,"
              + (-r3) + "," + (-r4) + ","+ (-r5) +",0,"
              + (r6) + "," + (r7) + ","+ (r8) +",0,"
              + (-t_est.x) + "," + (-t_est.y) + ","+ (-t_est.z) +",1"
            +")";

            console.log(r0, R_est.elements[0] );
            */


             var matrix3d1 = "translate(-50%, -50%) matrix3d("
              + (-R_est.elements[0]) + "," + (-R_est.elements[1]) + ","+ (-R_est.elements[2]) +",0,"
              + (-R_est.elements[3]) + "," + (-R_est.elements[4]) + ","+ (-R_est.elements[5]) +",0,"
              + (R_est.elements[6]) + "," + (R_est.elements[7]) + ","+ (R_est.elements[8]) +",0,"
              + (-t_est.x * 2) + "," + (-t_est.y * 2) + ","+ (-t_est.z * 1) +",1"
            +")";

                var distance_to_camera = t_est.length(); 
                if(distance_to_camera < min_tracker_d) {
                    min_tracker_d = distance_to_camera;
                    curr_main_tracker = tracker_idx;
                    main_tracker_timeout = 10;
                }

                //scanScarfNotice.classList.add('hidden');
            //if(err < 2){
                view3d.setTracker(tracker_idx, R_est, t_est, t_est2, R_est2, good, video.videoHeight < video.videoWidth);
            //}

            

            //sensors.addSample(-t_est.x, -t_est.y, -t_est.z);


            //arGroup.setRotationFromMatrix( R_est );
            //console.log(R_est);

           // var qest = PnP.mat_to_quat(R_est)
            //console.table(qest)
            //var quaternion = new THREE.Quaternion(qest[3], qest[2], qest[1], qest[0]);

            //var euler = new THREE.Euler();
           // euler.setFromQuaternion(quaternion);
            //console.log("euler", euler);

            //var quaternion = new THREE.Quaternion(qest[0], qest[1], qest[2], qest[3]);
           // arGroup.setRotationFromQuaternion(quaternion);
           // arGroup.matrixAutoUpdate = false;
           /* arGroup.matrix.set( 
                   R_est.elements[0], R_est.elements[3], R_est.elements[6], 0,
                   R_est.elements[1], R_est.elements[4], R_est.elements[7], 0,
                   R_est.elements[2], R_est.elements[5], R_est.elements[8], 0,
                   0, 0, 0, 1 );
*/
            //console.log(arGroup.matrix.elem);

            //console.log(PnP.mat_to_quat(R_est));

            //arGroup.applyMatrix3(R);//.add(t);
            //arGroup.position.x = 0;
            //arGroup.position.y = 0;
            //arGroup.position.z = 0;

            trackedElement.style.opacity = 0.5;
            if(err < 50){
            	trackedElement.style.backgroundColor = "pink";
            	trackedElement.style.transform = matrix3d1;
            } else {
                trackedElement.style.backgroundColor = "red";
            	//trackedElement.style.opacity = 0.5;
                /*console.warn(err, 
                    pattern_preview.cols*2, pattern_preview.rows*2,
                    tCorners(homo3x3.data, pattern_preview.cols*2, pattern_preview.rows*2)
                    );*/
            }

            } catch(err) {
                console.warn(err);
            }
    	} else {
    		//trackedElement.style.opacity = 0;
            curr_tracker_lvl[tracker_idx] = (curr_tracker_lvl[tracker_idx] + curr_tracker_lvl_mov[tracker_idx]) % num_train_levels;
            if(curr_tracker_lvl[tracker_idx] < 0) curr_tracker_lvl[tracker_idx] += num_train_levels;
    	}

    	
    }

    function render_pattern_shape(ctx, tracker_idx) {
        // get the projected pattern corners
        var shape_pts = tCorners(homo3x3[tracker_idx].data, pattern_preview[tracker_idx].cols*2, pattern_preview[tracker_idx].rows*2);

        ctx.strokeStyle = "rgb(0,255,0)";
        ctx.beginPath();

        ctx.moveTo(shape_pts[0].x,shape_pts[0].y);
        ctx.lineTo(shape_pts[1].x,shape_pts[1].y);
        ctx.lineTo(shape_pts[2].x,shape_pts[2].y);
        ctx.lineTo(shape_pts[3].x,shape_pts[3].y);
        ctx.lineTo(shape_pts[0].x,shape_pts[0].y);

        ctx.lineWidth=4;
        ctx.stroke();
    }

    function render_corners(corners, count, img, step) {
        var pix = (0xff << 24) | (0x00 << 16) | (0xff << 8) | 0x00;
        for(var i=0; i < count; ++i)
        {
            var x = corners[i].x;
            var y = corners[i].y;
            var off = (x + y * step);
            img[off] = pix;
            img[off-1] = pix;
            img[off+1] = pix;
            img[off-step] = pix;
            img[off+step] = pix;
        }
    }

    function render_mono_image(src, dst, sw, sh, dw) {
        var alpha = (0xff << 24);
        for(var i = 0; i < sh; ++i) {
            for(var j = 0; j < sw; ++j) {
                var pix = src[i*sw+j];
                dst[i*dw+j] = alpha | (pix << 16) | (pix << 8) | pix;
            }
        }
    }

    $(window).unload(function() {
        video.pause();
        video.src=null;
    });


	try {
		var attempts = 0;

		const readyListener = (event) => {
		    findVideoSize();
		};

		const findVideoSize = () => {
		    if(video.videoWidth > 0 && video.videoHeight > 0) {
		        video.removeEventListener('loadeddata', readyListener);
		        onDimensionsReady(video.videoWidth, video.videoHeight);
		    } else {
		        if(attempts < 10) {
		            attempts++;
		            setTimeout(findVideoSize, 200);
		        } else {
		            onDimensionsReady(640, 480);
		        }
		    }
		};
		const onDimensionsReady = (width, height) => {
		    app(width, height, tracker_cnt);
		    requestAnimationFrame(tick);
		};

		video.addEventListener('loadeddata', readyListener);

        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ 
            video: { 
                facingMode: "environment"//,
                /*width: {min: 640},
                height: {min: 640} */
            } 
            })
            .then((stream) =>  {
              video.srcObject = stream;
            })
            .catch((err) => {
              console.error(err);
            });
        }

	} catch (err) {
        alert(err);
        $('#canvas').hide();
	}

});