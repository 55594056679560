"use strict";

import "./linalg.min.js";
import * as THREE from 'three';

export function EPnP(params) {
    
    this.uc = params.uc;
    this.vc = params.vc;
    this.fu = params.fu; // focal length
    this.fv = params.fv;

    this.maximum_number_of_correspondences = 0;
    this.number_of_correspondences = 0;

    this.pws = null; // reference model in 3d or 2d
    this.us = null; // tracked 2d points
    this.alphas = null;
    this.pcs = null;

    this.cws = null;
    this.ccs = null;

    this.set_maximum_number_of_correspondences = function(n) {
      this.maximum_number_of_correspondences = n;
      this.reset_correspondences();
    }

    this.reset_correspondences = function()
    {
      this.number_of_correspondences = 0;

      this.pws = [];
      this.us = [];
      this.alphas = [];
      this.pcs = [];
    }

    this.add_correspondence = function(w, uv)
    {
        this.pws.push(w);
        this.us.push(uv);
        this.number_of_correspondences++;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33 B14 B24 B34 B44]

    this.compute_L_6x10 = function(ut)
    {
      // planar ut 9x9 - non-planar 12x12

      var v_idx = [
        12 * 11, 
        12 * 10, 
        12 *  9, 
        12 *  8
      ];

      var dv = [
        [ [],[],[],[],[],[] ],
        [ [],[],[],[],[],[] ],
        [ [],[],[],[],[],[] ],
        [ [],[],[],[],[],[] ]
      ];

      /*
      0 1 2 3 4 5 6 7 8 9 10 11
      12
      24
      36
      48
      60
      72
      84
      96
      108
      120
      132 133 134 135 136 137 138 139 140 141 142 143
      */

      /*
      dv 0 0 = 132 - 135
      dv 0 1 = 132 - 138
      dv 0 2 = 132 - 141
      dv 0 3 = 135 - 138
      dv 0 4 = 135 - 141
      dv 0 5 = 138 - 141

      dv 1 0 = 120 - 123
      dv 1 1 = 120 - 126
      dv 1 2 = 120 - 129
      dv 1 3 = 123 - 126
      dv 1 4 = 123 - 129
      dv 1 5 = 126 - 129

      dv 2 0 = 108 - 111
      dv 2 1 = 108 - 114
      dv 2 2 = 108 - 117
      dv 2 3 = 111 - 114
      dv 2 4 = 111 - 117
      dv 2 5 = 114 - 117
      
      dv 3 0 = 96 - 99
      dv 3 1 = 96 - 102
      dv 3 2 = 96 - 105
      dv 3 3 = 99 - 102
      dv 3 4 = 99 - 105
      dv 3 5 = 102 - 105
      */

      for(var i = 0; i < 4; i++) {
        var a = 0, b = 1;
        for(var j = 0; j < 6; j++) {
          dv[i][j] = new THREE.Vector3(
            ut[v_idx[i] + 3 * a    ] - ut[v_idx[i] + 3 * b],
            ut[v_idx[i] + 3 * a + 1] - ut[v_idx[i] + 3 * b + 1],
            ut[v_idx[i] + 3 * a + 2] - ut[v_idx[i] + 3 * b + 2]
          );

          //console.log("dv", i, j, "=", (v_idx[i] + 3 * a), " . ", v_idx[i] + 3 * b , ut[v_idx[i] + 3 * a    ], "-", ut[v_idx[i] + 3 * b]  )
         // console.log("dv", i, j, "=", (v_idx[i] + 3 * a +1), " . ", v_idx[i] + 3 * b +1, ut[v_idx[i] + 3 * a  +1  ], "-", ut[v_idx[i] + 3 * b +1]  )
         // console.log("dv", i, j, "=", (v_idx[i] + 3 * a +2), " . ", v_idx[i] + 3 * b +2, ut[v_idx[i] + 3 * a   +2 ], "-", ut[v_idx[i] + 3 * b +2]  )

          b++;
          if (b > 3) {
            a++;
            b = a + 1;
          }
        }
      }

      var l_6x10 = [];
      for(var i = 0; i < 6; i++) {
        var row = [];

        row[0] =        dv[0][i].dot(dv[0][i]);
        row[1] = 2.0 *  dv[0][i].dot(dv[1][i]);
        row[2] =        dv[1][i].dot(dv[1][i]);
        row[3] = 2.0 *  dv[0][i].dot(dv[2][i]);
        row[4] = 2.0 *  dv[1][i].dot(dv[2][i]);
        row[5] =        dv[2][i].dot(dv[2][i]);
        row[6] = 2.0 *  dv[0][i].dot(dv[3][i]);
        row[7] = 2.0 *  dv[1][i].dot(dv[3][i]);
        row[8] = 2.0 *  dv[2][i].dot(dv[3][i]);
        row[9] =        dv[3][i].dot(dv[3][i]);

        l_6x10.push(row);
      }

      return l_6x10;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33 xB14 xB24 xB34 xB44]

    this.compute_L_3x6_planar = function(ut)
    {

     // console.log("here", ut);
      // planar ut 9x9 - non-planar 12x12

      var v_idx = [
        9 * 8, 
        9 * 7, 
        9 * 6
      ];

      var dv = [
        [ [],[],[] ],
        [ [],[],[] ],
        [ [],[],[] ],
        [ [],[],[] ]
      ];

      /*
      0 1 2 3 4 5 6 7 8
      9
      18
      27
      36
      45
      54
      63
      72 73 74 75 76 77 78 79 80
      */

      for(var i = 0; i < 3; i++) {
        var a = 0, b = 1;
        for(var j = 0; j < 3; j++) {
          dv[i][j] = new THREE.Vector3(
            ut[v_idx[i] + 3 * a    ] - ut[v_idx[i] + 3 * b],
            ut[v_idx[i] + 3 * a + 1] - ut[v_idx[i] + 3 * b + 1],
            ut[v_idx[i] + 3 * a + 2] - ut[v_idx[i] + 3 * b + 2]
          );
         // console.log("dv", i, j, "=", (v_idx[i] + 3 * a), " . ", v_idx[i] + 3 * b , ut[v_idx[i] + 3 * a    ], "-", ut[v_idx[i] + 3 * b]  )

          b++;
          if (b > 2) {
            a++;
            b = a + 1;
          }
        }
      }

   //   console.log(dv);

      var l_3x6 = [];
      for(var i = 0; i < 3; i++) {
        var row = [];

        row[0] =        dv[0][i].dot(dv[0][i]);
        row[1] = 2.0 *  dv[0][i].dot(dv[1][i]);
        row[2] =        dv[1][i].dot(dv[1][i]);
        row[3] = 2.0 *  dv[0][i].dot(dv[2][i]);
        row[4] = 2.0 *  dv[1][i].dot(dv[2][i]);
        row[5] =        dv[2][i].dot(dv[2][i]);
        /*row[6] = 2.0 *  dv[0][i].dot(dv[3][i]);
        row[7] = 2.0 *  dv[1][i].dot(dv[3][i]);
        row[8] = 2.0 *  dv[2][i].dot(dv[3][i]);
        row[9] =        dv[3][i].dot(dv[3][i]);*/

        l_3x6.push(row);
      }

  //    console.log(l_3x6);

      return l_3x6;
    }


    this.dist2 = function(p1, p2)
    {
      return (p1[0] - p2[0]) * (p1[0] - p2[0]) +
        (p1[1] - p2[1]) * (p1[1] - p2[1]) +
        (p1[2] - p2[2]) * (p1[2] - p2[2]);
    }

    this.compute_rho = function()
    {
      var rho = [];

      rho.push(this.dist2(this.cws[0], this.cws[1]));
      rho.push(this.dist2(this.cws[0], this.cws[2]));
      rho.push(this.dist2(this.cws[0], this.cws[3]));
      rho.push(this.dist2(this.cws[1], this.cws[2]));
      rho.push(this.dist2(this.cws[1], this.cws[3]));
      rho.push(this.dist2(this.cws[2], this.cws[3]));

      return rho;
    }

    this.compute_rho_planar = function()
    {
      var rho = [];

      rho.push(this.dist2(this.cws[0], this.cws[1]));
      rho.push(this.dist2(this.cws[0], this.cws[2]));
      //rho.push(this.dist2(this.cws[0], this.cws[3]));
      rho.push(this.dist2(this.cws[1], this.cws[2]));
      //rho.push(this.dist2(this.cws[1], this.cws[3]));
      //rho.push(this.dist2(this.cws[2], this.cws[3]));

      return rho;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33 B14 B24 B34 B44]
    // betas_approx_1 = [B11 B12     B13         B14]

    this.find_betas_approx_1 = function(l_6x10, Rho, betas)
    {
      var l_6x4 = [
        [],
        [],
        [],
        [],
        [],
        []
      ];

      for(var i = 0; i < 6; i++) {
        l_6x4[i][0] = l_6x10[i][0]
        l_6x4[i][1] = l_6x10[i][1]
        l_6x4[i][2] = l_6x10[i][3]
        l_6x4[i][3] = l_6x10[i][6]
      }

      var b4 = Matrix.solve(Matrix(l_6x4), Matrix(Rho));

      var betas = [];

      if (b4[0] < 0) {
        betas.push(Math.sqrt(-b4[0]));
        betas.push(-b4[1] / betas[0]);
        betas.push(-b4[2] / betas[0]);
        betas.push(-b4[3] / betas[0]);
      } else {
        betas.push(Math.sqrt(b4[0]));
        betas.push(b4[1] / betas[0]);
        betas.push(b4[2] / betas[0]);
        betas.push(b4[3] / betas[0]);
      }

      return betas;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33]
    // betas_approx_1 = [B11 B12     B13        ]

    this.find_betas_approx_1_planar = function(l_3x6, Rho, betas)
    {
      var l_3x3 = [
        [],
        [],
        []
      ];

      for(var i = 0; i < 3; i++) {
        l_3x3[i][0] = l_3x6[i][0]
        l_3x3[i][1] = l_3x6[i][1]
        l_3x3[i][2] = l_3x6[i][3]
      }

      var b3 = Matrix.solve(Matrix(l_3x3), Matrix(Rho));

      var betas = [];

      if (b3[0] < 0) {
        betas.push(Math.sqrt(-b3[0]));
        betas.push(-b3[1] / betas[0]);
        betas.push(-b3[2] / betas[0]);
        //betas.push(-b4[3] / betas[0]);
      } else {
        betas.push(Math.sqrt(b3[0]));
        betas.push(b3[1] / betas[0]);
        betas.push(b3[2] / betas[0]);
        //betas.push(b4[3] / betas[0]);
      }

   //   console.warn("betas1", betas);

      return betas;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33 B14 B24 B34 B44]
    // betas_approx_2 = [B11 B12 B22                            ]

    this.find_betas_approx_2 = function(l_6x10, Rho, betas)
    {
      var l_6x3 = [
        [],
        [],
        [],
        [],
        [],
        []
      ];

      for(var i = 0; i < 6; i++) {
        l_6x3[i][0] = l_6x10[i][0]
        l_6x3[i][1] = l_6x10[i][1]
        l_6x3[i][2] = l_6x10[i][2]
      }

      var b3 = Matrix.solve(Matrix(l_6x3), Matrix(Rho));

      var betas = [];

      if (b3[0] < 0) {
        betas.push(Math.sqrt(-b3[0]));
        betas.push(
          b3[2] < 0 ?
          Math.sqrt(-b3[2]) : 0
          );
      } else {
        betas.push(Math.sqrt(b3[0]));
        betas.push(
          b3[2] > 0 ?
          Math.sqrt(b3[2]) : 0
          );
      }

      if (b3[1] < 0) betas[0] = -betas[0];

      betas[2] = 0;
      betas[3] = 0;

      return betas;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33]
    // betas_approx_2 = [B11 B12 B22            ]

    this.find_betas_approx_2_planar = function(l_3x6, Rho, betas)
    {
      var l_3x3 = [
        [],
        [],
        []
      ];

      for(var i = 0; i < 3; i++) {
        l_3x3[i][0] = l_3x6[i][0]
        l_3x3[i][1] = l_3x6[i][1]
        l_3x3[i][2] = l_3x6[i][2]
      }

      var b3 = Matrix.solve(Matrix(l_3x3), Matrix(Rho));

      var betas = [];

      if (b3[0] < 0) {
        betas.push(Math.sqrt(-b3[0]));
        betas.push(
          b3[2] < 0 ?
          Math.sqrt(-b3[2]) : 0
          );
      } else {
        betas.push(Math.sqrt(b3[0]));
        betas.push(
          b3[2] > 0 ?
          Math.sqrt(b3[2]) : 0
          );
      }

      if (b3[1] < 0) betas[0] = -betas[0];

      betas[2] = 0;
      //betas[3] = 0;

    //  console.warn("betas2", betas);

      return betas;
    }

    // betas10        = [B11 B12 B22 B13 B23 B33 B14 B24 B34 B44]
    // betas_approx_3 = [B11 B12 B22 B13 B23                    ]

    // the paper says this should actually include B33 as well

    this.find_betas_approx_3 = function(l_6x10, Rho, betas)
    {
      var l_6x5 = [
        [],
        [],
        [],
        [],
        [],
        []
      ];

      for(var i = 0; i < 6; i++) {
        l_6x5[i][0] = l_6x10[i][0]
        l_6x5[i][1] = l_6x10[i][1]
        l_6x5[i][2] = l_6x10[i][2]
        l_6x5[i][3] = l_6x10[i][3]
        l_6x5[i][4] = l_6x10[i][4]
      }

      var b5 = Matrix.solve(Matrix(l_6x5), Matrix(Rho));

      var betas = [];

      if (b5[0] < 0) {
        betas.push(Math.sqrt(-b5[0]));
        betas.push(
          b5[2] < 0 ?
          Math.sqrt(-b5[2]) : 0
          );
      } else {
        betas.push(Math.sqrt(b5[0]));
        betas.push(
          b5[2] > 0 ?
          Math.sqrt(b5[2]) : 0
          );
      }

      if (b5[1] < 0) betas[0] = -betas[0];

      betas[2] = b5[3] / betas[0];
      betas[3] = 0;

      return betas;
    }

    this.compute_A_and_b_gauss_newton = function(l_6x10, rho, betas)
    {
      var A = [];
      var b = [];

      for(var i = 0; i < 6; i++) {
        A.push(2 * l_6x10[i][0] * betas[0]  +     l_6x10[i][1] * betas[1] +     l_6x10[i][3] * betas[2] +     l_6x10[i][6] * betas[3]);
        A.push(l_6x10[i][1] * betas[0]      + 2 * l_6x10[i][2] * betas[1] +     l_6x10[i][4] * betas[2] +     l_6x10[i][7] * betas[3]);
        A.push(l_6x10[i][3] * betas[0]      +     l_6x10[i][4] * betas[1] + 2 * l_6x10[i][5] * betas[2] +     l_6x10[i][8] * betas[3]);
        A.push(l_6x10[i][6] * betas[0]      +     l_6x10[i][7] * betas[1] +     l_6x10[i][8] * betas[2] + 2 * l_6x10[i][9] * betas[3]);

        b.push(rho[i] -
         (
          l_6x10[i][0] * betas[0] * betas[0] +
          l_6x10[i][1] * betas[0] * betas[1] +
          l_6x10[i][2] * betas[1] * betas[1] +
          l_6x10[i][3] * betas[0] * betas[2] +
          l_6x10[i][4] * betas[1] * betas[2] +
          l_6x10[i][5] * betas[2] * betas[2] +
          l_6x10[i][6] * betas[0] * betas[3] +
          l_6x10[i][7] * betas[1] * betas[3] +
          l_6x10[i][8] * betas[2] * betas[3] +
          l_6x10[i][9] * betas[3] * betas[3]
          ));
      }

      return {A, b};
    }

    this.compute_A_and_b_gauss_newton_planar = function(l_3x6, rho, betas)
    {
      var A = [];
      var b = [];

      for(var i = 0; i < 3; i++) {
        A.push(2 * l_3x6[i][0] * betas[0]  +     l_3x6[i][1] * betas[1] +     l_3x6[i][3] * betas[2] /*+     l_3x6[i][6] * betas[3]*/);
        A.push(l_3x6[i][1] * betas[0]      + 2 * l_3x6[i][2] * betas[1] +     l_3x6[i][4] * betas[2] /*+     l_3x6[i][7] * betas[3]*/);
        A.push(l_3x6[i][3] * betas[0]      +     l_3x6[i][4] * betas[1] + 2 * l_3x6[i][5] * betas[2] /*+     l_3x6[i][8] * betas[3]*/);
        //A.push(l_3x6[i][6] * betas[0]      +     l_3x6[i][7] * betas[1] +     l_3x6[i][8] * betas[2] + 2 * l_3x6[i][9] * betas[3]);

        b.push(rho[i] -
         (
          l_3x6[i][0] * betas[0] * betas[0] +
          l_3x6[i][1] * betas[0] * betas[1] +
          l_3x6[i][2] * betas[1] * betas[1] +
          l_3x6[i][3] * betas[0] * betas[2] +
          l_3x6[i][4] * betas[1] * betas[2] +
          l_3x6[i][5] * betas[2] * betas[2]// +
          //l_6x10[i][6] * betas[0] * betas[3] +
          //l_6x10[i][7] * betas[1] * betas[3] +
          //l_6x10[i][8] * betas[2] * betas[3] +
          //l_6x10[i][9] * betas[3] * betas[3]
          ));
      }

      return {A, b};
    }

    this.qr_solve = function(A, b, nr, nc)
    {
      var A1 = [];
      var A2 = [];

      var ppAkk_idx = 0;      
      for(var k = 0; k < nc; k++) {
        var ppAik_idx = ppAkk_idx;
        var eta = Math.abs(A[ppAik_idx]);

        for(var i = k + 1; i < nr; i++) {
          var elt = Math.abs(A[ppAik_idx]);
          if (eta < elt) eta = elt;
          ppAik_idx += nc; // next column
        }

        if (eta == 0) {
          A1[k] = A2[k] = 0.0;
          console.log("God damnit, A is singular, this shouldn't happen.");
          return;
        } else {
          ppAik_idx = ppAkk_idx;
          var sum = 0.0; 
          var inv_eta = 1.0 / eta;
          for(var i = k; i < nr; i++) {
            A[ppAik_idx] *= inv_eta;
            sum += A[ppAik_idx] * A[ppAik_idx];
            ppAik_idx += nc;
          }

          var sigma = Math.sqrt(sum);
          if (A[ppAkk_idx] < 0) sigma = -sigma;

          A[ppAkk_idx] += sigma;

          A1[k] = sigma * A[ppAkk_idx];
          A2[k] = -eta * sigma;

          for(var j = k + 1; j < nc; j++) {
            ppAik_idx = ppAkk_idx;
            sum = 0;
            for(var i = k; i < nr; i++) {
              sum += A[ppAik_idx] * A[ppAik_idx + j - k]; // TODO CHECK
              ppAik_idx += nc;
            }

            var tau = sum / A1[k];
            ppAik_idx = ppAkk_idx;
            for(var i = k; i < nr; i++) {
              A[ppAik_idx + j - k] -= tau * A[ppAik_idx]; // TODO CHECK
              ppAik_idx += nc;
            }
          }
        }
        ppAkk_idx += nc + 1;
      }

      // b <- Qt b
      var ppAjj_idx = 0;
      var pb = b;
      for(var j = 0; j < nc; j++) {
        var ppAij_idx = ppAjj_idx;
        var tau = 0;
        for(var i = j; i < nr; i++) {
          tau += A[ppAij_idx] * pb[i];
          ppAij_idx += nc;
        }
        tau /= A1[j];
        ppAij_idx = ppAjj_idx;
        for(var i = j; i < nr; i++) {
          pb[i] -= tau * A[ppAij_idx];
          ppAij_idx += nc;
        }
        ppAjj_idx += nc + 1;
      }

      // X = R-1 b
      var pX = [];
      pX[nc - 1] = pb[nc - 1] / A2[nc - 1];
      for(var i = nc - 2; i >= 0; i--) {
        var ppAij_idx = i * nc + (i + 1);
        var sum = 0;

        for(var j = i + 1; j < nc; j++) {
          sum += A[ppAij_idx] * pX[j];
          ppAij_idx++;
        }
        pX[i] = (pb[i] - sum) / A2[i];
      }

      return pX;
    }

    this.gauss_newton = function(L_6x10, Rho, betas)
    {
      var iterations_number = 5;

      for(var k = 0; k < iterations_number; k++) {
        let {A, b} = this.compute_A_and_b_gauss_newton(L_6x10, Rho, betas);
        var x = this.qr_solve(A, b, 6, 4);
        for(var i = 0; i < 4; i++)
          betas[i] += x[i];
      }
    }

    this.gauss_newton_planar = function(L_3x6, Rho, betas)
    {
      var iterations_number = 5;

      for(var k = 0; k < iterations_number; k++) {
        let {A, b} = this.compute_A_and_b_gauss_newton_planar(L_3x6, Rho, betas);
        var x = this.qr_solve(A, b, 3, 3);
        for(var i = 0; i < 3; i++)
          betas[i] += x[i];
      }
    }

    this.compute_ccs = function(betas, ut)
    {
      this.ccs = [
          [0,0,0], 
          [0,0,0], 
          [0,0,0], 
          [0,0,0]
      ];
      
      for(var i = 0; i < 4; i++) {
        var v_idx = 12 * (11 - i);
        for(var j = 0; j < 4; j++)
          for(var k = 0; k < 3; k++)
            this.ccs[j][k] += betas[i] * ut[v_idx + 3 * j + k];
      }
    }

    this.compute_ccs_planar = function(betas, ut)
    {
      this.ccs = [
          [0,0,0], 
          [0,0,0], 
          [0,0,0]
      ];
      
   //   console.warn(betas, ut);

      for(var i = 0; i < 3; i++) {
        var v_idx = 9 * (8 - i);
        for(var j = 0; j < 3; j++)
          for(var k = 0; k < 3; k++)
            this.ccs[j][k] += betas[i] * ut[v_idx + 3 * j + k];
      }

   //   console.warn(this.ccs);
    }

    this.compute_pcs = function()
    {
      this.pcs = [];
      for(var i = 0; i < this.number_of_correspondences; i++) {
        this.pcs.push(
          new THREE.Vector3(
            this.alphas[i].x * this.ccs[0][0] 
              + this.alphas[i].y * this.ccs[1][0] 
              + this.alphas[i].z * this.ccs[2][0] 
              + this.alphas[i].w * this.ccs[3][0],
            this.alphas[i].x * this.ccs[0][1] 
              + this.alphas[i].y * this.ccs[1][1] 
              + this.alphas[i].z * this.ccs[2][1] 
              + this.alphas[i].w * this.ccs[3][1],
            this.alphas[i].x * this.ccs[0][2] 
              + this.alphas[i].y * this.ccs[1][2] 
              + this.alphas[i].z * this.ccs[2][2] 
              + this.alphas[i].w * this.ccs[3][2]
          )
        );
      }
    }

    this.compute_pcs_planar = function()
    {
      this.pcs = [];
      for(var i = 0; i < this.number_of_correspondences; i++) {
        this.pcs.push(
          new THREE.Vector3(
            this.alphas[i].x * this.ccs[0][0] 
              + this.alphas[i].y * this.ccs[1][0] 
              + this.alphas[i].z * this.ccs[2][0],
            this.alphas[i].x * this.ccs[0][1] 
              + this.alphas[i].y * this.ccs[1][1] 
              + this.alphas[i].z * this.ccs[2][1],
            this.alphas[i].x * this.ccs[0][2] 
              + this.alphas[i].y * this.ccs[1][2] 
              + this.alphas[i].z * this.ccs[2][2] 
          )
        );
      }
    //  console.warn(this.pcs, this.alphas, this.ccs);
    }

    this.solve_for_sign = function()
    {
    //  console.log( "solve_for_sign", this.pcs[0].z );
      if (this.pcs[0].z < 0.0) {
        for(var i = 0; i < this.ccs.length; i++)
          for(var j = 0; j < 3; j++)
            this.ccs[i][j] = -this.ccs[i][j];

        for(var i = 0; i < this.number_of_correspondences; i++) {
          this.pcs[i].x = -this.pcs[i].x;
          this.pcs[i].y = -this.pcs[i].y;
          this.pcs[i].z = -this.pcs[i].z;
        }
      }
    }

    this.reprojection_error = function(R, t)
    {
      var sum2 = 0.0;

      for(var i = 0; i < this.number_of_correspondences; i++) {
        var pw = this.pws[i];
 
        var Xc = R[0].dot(pw) + t.x;
        var Yc = R[1].dot(pw) + t.y;
        var inv_Zc = 1.0 / (R[2].dot(pw) + t.z);
        var ue = this.uc + this.fu * Xc * inv_Zc;
        var ve = this.vc + this.fv * Yc * inv_Zc;
        var u = this.us[i].x;
        var v = this.us[i].y;

        sum2 += Math.sqrt( (u - ue) * (u - ue) + (v - ve) * (v - ve) );
      }

      return sum2 / this.number_of_correspondences;
    }

    this.reprojection_error2 = function(R, t)
    {
      R = [
        new THREE.Vector3(R.elements[0], R.elements[3], R.elements[6]),
        new THREE.Vector3(R.elements[1], R.elements[4], R.elements[7]),
        new THREE.Vector3(R.elements[2], R.elements[5], R.elements[8])
      ];

      var sum2 = 0.0;

      for(var i = 0; i < this.number_of_correspondences; i++) {
        var pw = this.pws[i];
 
        var Xc = R[0].dot(pw) + t.x;
        var Yc = R[1].dot(pw) + t.y;
        var inv_Zc = 1.0 / (R[2].dot(pw) + t.z);
        var ue = this.uc + this.fu * Xc * inv_Zc;
        var ve = this.vc + this.fv * Yc * inv_Zc;
        var u = this.us[i].x;
        var v = this.us[i].y;

        sum2 += Math.sqrt( (u - ue) * (u - ue) + (v - ve) * (v - ve) );
      }

      return sum2 / this.number_of_correspondences;
    }

    this.compute_R_and_t = function(ut, betas, Rs, ts)
    {
      this.compute_ccs(betas, ut);
      this.compute_pcs();

      this.solve_for_sign();

      let {R, t} = this.estimate_R_and_t();

      Rs.push(R);
      ts.push(t);

      return this.reprojection_error(R, t);
    }

    this.compute_R_and_t_planar = function(ut, betas, Rs, ts)
    {
      this.compute_ccs_planar(betas, ut);
      this.compute_pcs_planar();

      this.solve_for_sign();

      let {R, t} = this.estimate_R_and_t();

      Rs.push(R);
      ts.push(t);

      return this.reprojection_error(R, t);
    }

    this.estimate_R_and_t = function()
    {

      // pws = 3D points in arbitrary reference frame
      // pcs = 3D points in camera reference frame

      // computer centers
      var pc0 = new THREE.Vector3(0,0,0);
      var pw0 = new THREE.Vector3(0,0,0);
      for(var i = 0; i < this.number_of_correspondences; i++) {
        pc0.add(this.pcs[i]);
        pw0.add(this.pws[i]);
      }
      pc0.divideScalar(this.number_of_correspondences);
      pw0.divideScalar(this.number_of_correspondences);

      var abt = [
        [0, 0 ,0],
        [0, 0 ,0],
        [0, 0 ,0]
        ];
/*
      console.log("this.pws", this.pws);
      console.log("pw0", pw0);
      console.log("this.pcs", this.pcs);
      console.log("pc0", pc0);
*/
      for(var i = 0; i < this.number_of_correspondences; i++) {
        var pc = this.pcs[i];
        var pw = this.pws[i];
        for(var j = 0; j < 3; j++) {
          abt[j][0] += (pc.getComponent(j) - pc0.getComponent(j)) * (pw.x - pw0.x);
          abt[j][1] += (pc.getComponent(j) - pc0.getComponent(j)) * (pw.y - pw0.y);
          abt[j][2] += (pc.getComponent(j) - pc0.getComponent(j)) * (pw.z - pw0.z);
        }
      }
   //   console.log("abt", abt);

      var abt_u = Matrix(abt); // will be overwritten
      var abt_v = Matrix(abt_u.cols, abt_u.cols);
      Matrix.factorSVD(abt_u, Matrix(abt_u.cols, 1), abt_v);

      var R = [
        [],
        [],
        []
      ];

  //    console.log("abt_u", abt_u);

      for(var i = 0; i < 3; i++)
        for(var j = 0; j < 3; j++){
          R[i][j] = new THREE.Vector3(abt_u[3 * i], abt_u[3 * i + 1], abt_u[3 * i + 2]).dot(
            new THREE.Vector3(abt_v[3 * j], abt_v[3 * j + 1], abt_v[3 * j + 2])
          );
        /*  console.log("R[" + i + "][" + j + "]",
              new THREE.Vector3(abt_u[3 * i], abt_u[3 * i + 1], abt_u[3 * i + 2]),
              new THREE.Vector3(abt_v[3 * j], abt_v[3 * j + 1], abt_v[3 * j + 2])

            );*/
        }
          

      var det =
        R[0][0] * R[1][1] * R[2][2] + R[0][1] * R[1][2] * R[2][0] + R[0][2] * R[1][0] * R[2][1] -
        R[0][2] * R[1][1] * R[2][0] - R[0][1] * R[1][0] * R[2][2] - R[0][0] * R[1][2] * R[2][1];

   //   console.log("det", det);
/*
      if (det < 0) {
        R[2][0] = -R[2][0];
        R[2][1] = -R[2][1];
        R[2][2] = -R[2][2];
      }
      */

      if (det < 0) {
        R[0][2] = -R[0][2];
        R[1][2] = -R[1][2];
        R[2][2] = -R[2][2];
      }

      R = [
        new THREE.Vector3().fromArray(R[0]),
        new THREE.Vector3().fromArray(R[1]),
        new THREE.Vector3().fromArray(R[2])
      ];

 //     console.log("CHECK", R[1], (pw0), pc0.y, R[1].dot(pw0));

      var t = new THREE.Vector3(
          pc0.x - R[0].dot(pw0),
          pc0.y - R[1].dot(pw0),
          pc0.z - R[2].dot(pw0)
      );

      return {R: R, t: t};
    }

    // KEY METHOD:

   this.compute_pose = function() // Matrix3, Vector3
    {
      this.choose_control_points();

      var planar = false;
      if(this.cws[0][0] === this.cws[3][0] 
        && this.cws[0][1] === this.cws[3][1] 
        && this.cws[0][2] === this.cws[3][2]) planar = true; // TODO: validate if first and last are always the same in planar case
      
      if(planar) this.cws.splice(3);

   //   console.log("planar:", planar);
   //   console.log("control points:", this.cws);

      if(planar) this.compute_barycentric_coordinates_planar();
      else this.compute_barycentric_coordinates();

      var M = []; // 2*this.number_of_correspondences rows x 12 columns => planar: 2*this.number_of_correspondences rows x 9 columns
      
      if(planar) for(var i = 0; i < this.number_of_correspondences; i++)
        this.fill_M_planar(M, 2 * i, this.alphas[i], this.us[i]);
      else for(var i = 0; i < this.number_of_correspondences; i++)
        this.fill_M(M, 2 * i, this.alphas[i], this.us[i]);

      var mtm = [];
      var m_cols = planar ? 9 : 12;
      for(var j = 0; j < m_cols; j++) { // row of result
          var row = [];
          for(var i = 0; i < m_cols; i++) { // column of result
              var val = 0;
              for(var k = 0; k < 2 * this.number_of_correspondences; k++) {
                  val += M[k*m_cols +i] * M[k*m_cols +j];
              }
              row.push(val);
          }
          mtm.push(row);
      }


      // solution is the weighted sum of the null eigenvectors of M

      var ut = Matrix(mtm); // will be overwritten
      var w = Matrix(ut.cols, 1);
      Matrix.factorSVD(ut, w, Matrix(ut.cols, ut.cols));
      this.adapt_svd_result(ut, w);

  //    console.warn(ut, w);
      /*
      Given that the correct linear combination is the
      one that yields 3D camera coordinates for the control
      points that preserve their distances, we can find the appropriate
      weights by solving small systems of quadratic
      equations,*/

      var ts = [];
      var Rs = [];
      var rep_errors = [];

      if(planar){
        
        var l_3x6 = this.compute_L_3x6_planar(ut);
        var rho = this.compute_rho_planar();

    //    console.log("rho", rho);

        var betas1 = this.find_betas_approx_1_planar(l_3x6, rho);
        this.gauss_newton_planar(l_3x6, rho, betas1);
        rep_errors[0] = this.compute_R_and_t_planar(ut, betas1, Rs, ts);
    //    console.log("rep_errors1", betas1, rep_errors[0], "Rs", Rs, ts);

        var betas2 = this.find_betas_approx_2_planar(l_3x6, rho);
        this.gauss_newton_planar(l_3x6, rho, betas2);
        rep_errors[1] = this.compute_R_and_t_planar(ut, betas2, Rs, ts);
    //    console.log("rep_errors2", betas2, rep_errors[1], "Rs", Rs, ts);

      } else {

        var l_6x10 = this.compute_L_6x10(ut);
        var rho = this.compute_rho();

    //    console.log("rho", rho);

        var betas1 = this.find_betas_approx_1(l_6x10, rho);
       
        this.gauss_newton(l_6x10, rho, betas1);
        rep_errors[0] = this.compute_R_and_t(ut, betas1, Rs, ts);
  //      console.log("rep_errors1", betas1, rep_errors[0], "Rs", Rs, ts);

        var betas2 = this.find_betas_approx_2(l_6x10, rho);
        this.gauss_newton(l_6x10, rho, betas2);
        rep_errors[1] = this.compute_R_and_t(ut, betas2, Rs, ts);
    //    console.log("rep_errors2", betas2, rep_errors[1], "Rs", Rs, ts);

        var betas3 = this.find_betas_approx_3(l_6x10, rho);
        this.gauss_newton(l_6x10, rho, betas3);
        rep_errors[2] = this.compute_R_and_t(ut, betas3, Rs, ts);
    //    console.log("rep_errors3", betas3, rep_errors[2], "Rs", Rs, ts);
      }
      

      var N = 0;
      if (rep_errors[1] < rep_errors[0]) N = 1;
      if (rep_errors[2] < rep_errors[N]) N = 2;

      //console.warn(Rs);
      var R_est = new THREE.Matrix3();
      R_est.set(
        Rs[N][0].x, Rs[N][0].y, Rs[N][0].z,
        Rs[N][1].x, Rs[N][1].y, Rs[N][1].z,
        Rs[N][2].x, Rs[N][2].y, Rs[N][2].z
      );

      return {
        R_est: R_est,
        t_est: ts[N].clone(),
        err: rep_errors[N]
      }
    }

    this.fill_M = function(M, row, as, uv)
    {
      var m1_idx = row * 12;
      var m2_idx = m1_idx + 12;

      for(var i = 0; i < 4; i++) {
        M[m1_idx + 3 * i    ] = as.getComponent(i) * this.fu;
        M[m1_idx + 3 * i + 1] = 0.0;
        M[m1_idx + 3 * i + 2] = as.getComponent(i) * (this.uc - uv.x);

        M[m2_idx + 3 * i    ] = 0.0;
        M[m2_idx + 3 * i + 1] = as.getComponent(i) * this.fv;
        M[m2_idx + 3 * i + 2] = as.getComponent(i) * (this.vc - uv.y);
      }
    }

    this.fill_M_planar = function(M, row, as, uv)
    {
      var m1_idx = row * 9;
      var m2_idx = m1_idx + 9;

      for(var i = 0; i < 3; i++) { // TODO: validate 3 instead of 4 in planar case
        M[m1_idx + 3 * i    ] = as.getComponent(i) * this.fu;
        M[m1_idx + 3 * i + 1] = 0.0;
        M[m1_idx + 3 * i + 2] = as.getComponent(i) * (this.uc - uv.x);

        M[m2_idx + 3 * i    ] = 0.0;
        M[m2_idx + 3 * i + 1] = as.getComponent(i) * this.fv;
        M[m2_idx + 3 * i + 2] = as.getComponent(i) * (this.vc - uv.y);
      }
    }

    this.adapt_svd_result = function(U, w){

      var newRows = [];

      for(var i = 0; i < U.cols; i++){
        var newRow = {
          U:[], 
          w: w[i], 
          //sign: U[i * U.cols + i] < 0 ? 1 : -1
        };
        for(var j = 0; j < U.rows; j++){
          //console.log(j * U.cols + i);
          newRow.U.push(U[j * U.cols + i]);
        }
        newRows.push(newRow);
      }

      newRows.sort((a, b) => b.w - a.w);

      for(var i = 0; i < U.cols; i++){
        newRows[i].sign = U[i * U.cols + i] < 0 ? 1 : -1
      }

      //console.log(newRows);

      var k = 0;
      for(var i = 0; i < newRows.length; i++){
        var newRow = newRows[i];
        w[i] = newRow.w;
        for(var j = 0; j < newRow.U.length; j++){
          U[k++] = newRow.sign * newRow.U[j];
          //console.log(newRow.U[j])
        }
      }

    }

    this.choose_control_points = function()
    {
        // Take C0 as the reference points centroid:
        this.cws = [
            [0, 0, 0], 
            [], 
            [], 
            []
        ];
        for(var i = 0; i < this.number_of_correspondences; i++){
            var w = this.pws[i];
            this.cws[0][0] += w.x;
            this.cws[0][1] += w.y;
            this.cws[0][2] += w.z;
        }

        this.cws[0][0] /= this.number_of_correspondences;
        this.cws[0][1] /= this.number_of_correspondences;
        this.cws[0][2] /= this.number_of_correspondences;

        var pw0 = []; // column major order!
        for(var i = 0; i < this.number_of_correspondences; i++) {
            var w = this.pws[i];
            pw0.push(w.x - this.cws[0][0]);
            pw0.push(w.y - this.cws[0][1]);
            pw0.push(w.z - this.cws[0][2]);
        }

        var pw0tpw0 = [];
        for(var j = 0; j < 3; j++) { // row 
            var row = [];
            for(var i = 0; i < 3; i++) { // column
                var val = 0;
                for(var k = 0; k < this.number_of_correspondences; k++) {
                    val += pw0[k*3 +i] * pw0[k*3 +j];
                    //console.log("row", j, "col", i, k*3 +i, k*3 +j);
                }
                row.push(val);
            }
            pw0tpw0.push(row);
        }

        // console.warn(pw0tpw0);

        var uct = Matrix(pw0tpw0); // will be overwritten
        //console.warn("pw0tpw0", pw0tpw0);

        var dc = Matrix(uct.cols, 1);
        Matrix.factorSVD(uct, dc, Matrix(uct.cols, uct.cols));
        //var uct = Matrix.transpose(uc);

        //console.warn("uc", uct);
        //console.warn("dc", dc);

        this.adapt_svd_result(uct, dc);



       // console.warn("transi", uc, dc);


       
      
        /* Case 1 ========================================
        uct = 
        0.0182851 0.0237642 0.99955
        0.188169 -0.981935 0.0199031
        0.981966 0.187721 -0.0224265
        
        dc = 
        7.9351 3.11671 0.996068

        0: -0.9819663666577606
        1: -0.18816936579001062
        2: 0.018285090405615812
        3: -0.18772082689896685
        4: 0.981934904081188
        5: 0.023764159893325813
        6: 0.022426455389213583
        7: -0.019903113456258486
        8: 0.999550358998196
        */
        /*
        var uct = [
          uc[2], uc[5], uc[8],
          -uc[1], -uc[4], -uc[7],
          -uc[0], -uc[3], -uc[6]
        ]
        */



        /* Case 2 ========================================
        uct =
        -0.00272773 0.209831 0.977734
        -0.457717 -0.869565 0.18534
        0.889094 -0.44702 0.0984153

        dc =
        8.91312 3.14386 0.687486

        0: 0.8890937454242196
        1: 0.4577170210213595
        2: 0.0027277307172478343
        3: -0.4470198413387099
        4: 0.8695654522144808
        5: -0.20983132693794884
        6: 0.09841531027765987
        7: -0.18534037062215608
        8: -0.9777337949158726
        */
       /*
        var uct = [
          -uc[2], -uc[5], -uc[8],
          -uc[1], -uc[4], -uc[7],
          uc[0], uc[3], uc[6]
        ];*/


        /* Case 3 ========================================
        uct
        -0.0384774 -0.26955 0.962217
        0.627645 -0.755799 -0.186627
        0.777548 0.59675 0.198263
        dc
        9.35854 4.62966 2.14375

        0: -0.7775482347590172
        1: -0.6276449876765652
        2: -0.03847742282976583
        3: -0.5967499030971491
        4: 0.7557989794591383
        5: -0.26955046986059605
        6: -0.19826319824082064
        7: 0.18662709367347924
        8: 0.9622172478865213
        */
/*
        var uct = [
          uc[2], uc[5], uc[8],
          -uc[1], -uc[4], -uc[7],
          -uc[0], -uc[3], -uc[6]
        ]
*/

        /* Case 4 ========================================
        uct
        -0.50254 -0.403564 0.764585
        -0.803144 -0.109437 -0.585647
        0.32002 -0.908383 -0.269123

        dc
        6.32794 1.47332 1.08085

        0: -0.5025399565730958
        1: 0.8031441759035269
        2: 0.32002035054001704
        3: -0.4035640173779948
        4: 0.10943736203249368
        5: -0.9083829300846108
        6: 0.7645846427474486
        7: 0.5856474165431051
        8: -0.2691234429980827

        */
        /*
        var uct = [
          uc[0], uc[3], uc[6],
          -uc[1], -uc[4], -uc[7],
          uc[2], uc[5], uc[8]
        ]
        */

        /*========================================
        0: -0.5025399565730958
        1: -0.4035640173779948
        2: 0.7645846427474486
        3: -0.8031441759035269
        4: -0.10943736203249368
        5: -0.5856474165431051
        6: 0.32002035054001704
        7: -0.9083829300846108
        8: -0.2691234429980827


        0: 6.327939824569128
        1: 1.4733150882182775
        2: 1.0808514565452265
        */


        /*
        var sgnR1 = uc[8] < 0 ? 1 : -1;
        var sgnR2 = uc[4] < 0 ? 1 : -1;
        var sgnR3 = uc[0] < 0 ? 1 : -1;

        var uct = [
          sgnR1*uc[2], sgnR1*uc[5], sgnR1*uc[8],
          sgnR2*uc[1], sgnR2*uc[4], sgnR2*uc[7],
          sgnR3*uc[0], sgnR3*uc[3], sgnR3*uc[6]
        ];
        

        /*

        0: 0.8890937454242196
        1: 0.4577170210213595
        2: 0.0027277307172478343
        3: -0.4470198413387099
        4: 0.8695654522144808
        5: -0.20983132693794884
        6: 0.09841531027765987
        7: -0.18534037062215608
        8: -0.9777337949158726

        */


        


        //dc = [dc[2], dc[1], dc[0]];

 

       // console.warn("------------", uct, dc);

        for(var i = 1; i < 4; i++) { // row - first row has been already set
            var k = Math.sqrt(dc[i-1] / this.number_of_correspondences);
            for(var j = 0; j < 3; j++) { // column
                this.cws[i][j] = this.cws[0][j] + k * uct[3 * (i - 1) + j];
            }
        }
  
    }

    this.compute_barycentric_coordinates = function()
    {
        // 3 x 3
        this.cc = [
            [], 
            [], 
            []
        ];

        // this.cws 4 x 3 - 4 control points in non-planar case
        for(var i = 0; i < 3; i++)
          for(var j = 1; j < 4; j++)
            this.cc[i][j - 1] = this.cws[j][i] - this.cws[0][i];
                
        var cc_inv = Matrix.invertSVD(Matrix(this.cc)); // 3 cols x 3 rows 


     //   console.warn(cc_inv);

        for(var i = 0; i < this.number_of_correspondences; i++) {
          var w = this.pws[i];

          var a = [];
          for(var j = 0; j < 3; j++)
            a[1 + j] =
              cc_inv[3 * j    ] * (w.x - this.cws[0][0]) +
              cc_inv[3 * j + 1] * (w.y - this.cws[0][1]) +
              cc_inv[3 * j + 2] * (w.z - this.cws[0][2]);

          a[0] = 1.0 - a[1] - a[2] - a[3];

          this.alphas.push(new THREE.Vector4(a[0], a[1], a[2], a[3]));
        }
    }

    this.compute_barycentric_coordinates_planar = function()
    {
        // 3 x 2
        this.cc = [
            [], 
            [], 
            []
        ];

        // this.cws 3 x 3 - 3 control points in planar case
        for(var i = 0; i < 3; i++)
          for(var j = 1; j < 3; j++)
            this.cc[i][j - 1] = this.cws[j][i] - this.cws[0][i];
                
        var cc_inv = Matrix.invertSVD(Matrix(this.cc));

    //    console.warn(cc_inv); // 3 cols x 2 rows 

        for(var i = 0; i < this.number_of_correspondences; i++) {
          var w = this.pws[i];

          var a = [];
          for(var j = 0; j < 2; j++) // TODO: validate if this drops from 3 to 2 
            a[1 + j] =
              cc_inv[3 * j    ] * (w.x - this.cws[0][0]) +
              cc_inv[3 * j + 1] * (w.y - this.cws[0][1]) +
              cc_inv[3 * j + 2] * (w.z - this.cws[0][2]);

          a[0] = 1.0 - a[1] - a[2];

          this.alphas.push(new THREE.Vector3(a[0], a[1], a[2]));
        }
    }

    this.relative_error = function(Rtrue, ttrue, Rest, test)
    {
 
      var qtrue =this.mat_to_quat(Rtrue);
      var qest = this.mat_to_quat(Rest);

      //console.log(qtrue, qest);

      var rot_err1 = Math.sqrt((qtrue[0] - qest[0]) * (qtrue[0] - qest[0]) +
           (qtrue[1] - qest[1]) * (qtrue[1] - qest[1]) +
           (qtrue[2] - qest[2]) * (qtrue[2] - qest[2]) +
           (qtrue[3] - qest[3]) * (qtrue[3] - qest[3]) ) /
        Math.sqrt(qtrue[0] * qtrue[0] + qtrue[1] * qtrue[1] + qtrue[2] * qtrue[2] + qtrue[3] * qtrue[3]);

      var rot_err2 = Math.sqrt((qtrue[0] + qest[0]) * (qtrue[0] + qest[0]) +
           (qtrue[1] + qest[1]) * (qtrue[1] + qest[1]) +
           (qtrue[2] + qest[2]) * (qtrue[2] + qest[2]) +
           (qtrue[3] + qest[3]) * (qtrue[3] + qest[3]) ) /
        Math.sqrt(qtrue[0] * qtrue[0] + qtrue[1] * qtrue[1] + qtrue[2] * qtrue[2] + qtrue[3] * qtrue[3]);

      var rot_err = Math.min(rot_err1, rot_err2);

    //  console.log("ERR", rot_err1, rot_err2);

      var transl_err =
        Math.sqrt(
          (ttrue.x - test.x) * (ttrue.x - test.x) +
          (ttrue.y - test.y) * (ttrue.y - test.y) +
          (ttrue.z - test.z) * (ttrue.z - test.z)
          ) / Math.sqrt(ttrue.x * ttrue.x + ttrue.y * ttrue.y + ttrue.z * ttrue.z);

      return {rot_err: rot_err, transl_err: transl_err};

    }

    this.mat_to_quat = function(R)
    {
      var q = [];

      var tr = R.elements[0] + R.elements[4] + R.elements[8];

      var n4;
      if (tr > 0) {
        q[0] = R.elements[7] - R.elements[5];
        q[1] = R.elements[2] - R.elements[6];
        q[2] = R.elements[3] - R.elements[1];
        q[3] = tr + 1;
        n4 = q[3];
      } else if ( (R.elements[0] > R.elements[4]) && (R.elements[0] > R.elements[8]) ) {
        q[0] = 1 + R.elements[0] - R.elements[4] - R.elements[8];
        q[1] = R.elements[1] + R.elements[3];
        q[2] = R.elements[2] + R.elements[6];
        q[3] = R.elements[7] - R.elements[5];
        n4 = q[0];
      } else if (R.elements[4] > R.elements[8]) {
        q[0] = R.elements[1] + R.elements[3];
        q[1] = 1 + R.elements[4] - R.elements[0] - R.elements[8];
        q[2] = R.elements[5] + R.elements[7];
        q[3] = R.elements[2] - R.elements[6];
        n4 = q[1];
      } else {
        q[0] = R.elements[2] + R.elements[6];
        q[1] = R.elements[5] + R.elements[7];
        q[2] = 1 + R.elements[8] - R.elements[0] - R.elements[4];
        q[3] = R.elements[3] - R.elements[1];
        n4 = q[2];
      }
      var scale = 0.5 / Math.sqrt(n4);

      q[0] *= scale;
      q[1] *= scale;
      q[2] *= scale;
      q[3] *= scale;

      return q;
    }

}

